import React, { useState } from 'react';
import Navbar from '../components/home/NavBar';
import Footer from '../components/home/Footer';

const ContactForm: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [query, setQuery] = useState<string>('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    if (!isValidEmail){
      alert('Enter a valid email!');
      return

    }
    
    try {
      const response = await fetch('/api/contact-submission', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email, subject:subject, query: query }),
      });
      if (response.ok) {
        alert('Form submitted successfully!');
      } else {
        throw new Error('Failed to submit form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to submit form');
    }

    // Resetting the form fields after submission
    setEmail('');
    setSubject('');
    setQuery('');
  };

  return (
    <div className="App">
    <Navbar />
    <div className="contact-form-container">
      <form onSubmit={handleSubmit}>
        <p>FeedBack and Questions</p>
        <input
          type="email"
          placeholder="Your Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          required
        />
        <textarea
          placeholder="Your Query"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          required
        />
        <button type="submit">Submit</button>
      </form>
    </div>
    <Footer/>
    </div>
  );
};

export default ContactForm;
