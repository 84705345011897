import React, {useRef} from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism'; // Choose your preferred style


interface CodeMessage {
  code?: string;
  code_explanation?: [string];
  solution_type?: string;
  space_complexity?: string;
  time_complexity?: string;
  language?: string;
  user_query: string;
}

interface TextMessage {
  message: string;
}

type Message = CodeMessage | TextMessage;

interface ChatMessageProps {
  message: Message;
}

const ChatMessage: React.FC<ChatMessageProps> = ({ message }) => {
  const codeRef = useRef<HTMLDivElement>(null);

  const handleCopyClick = () => {
    if (codeRef.current) {
      const range = document.createRange();
      range.selectNode(codeRef.current);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(range);
      document.execCommand('copy');
      window.getSelection()?.removeAllRanges();
    }
  };
  
  if ('message' in message) {
    return <p className="text-message">{message.message}</p>;
  }

  var { code, code_explanation, time_complexity, space_complexity, language, user_query } = message as CodeMessage; // Cast message to CodeMessage
  code  = code ? code.replace(/```/g, '') : '';

  return (
    <div className="code-message">
      
      <div ref={codeRef}>
      <button className="copy-button" onClick={handleCopyClick}>
        Copy Code
      </button>
        <SyntaxHighlighter language={language || 'plaintext'} style={dracula}>
          {code ? code.trim() : ''}
        </SyntaxHighlighter>
        
      </div>
      <p><strong>Question</strong>: {user_query}</p>
      <div className="explanation-list">
        <p><strong>Explanation:</strong></p>
        <ul>
          {code_explanation ? code_explanation.map((explanation, index) => (
            <li key={index}>{explanation}</li>
          )) : ''}
        </ul>
      </div>
      <p><strong>Time Complexity</strong>: {time_complexity}</p>
      <p><strong>Space Complexity</strong>: {space_complexity}</p>
      {/* Display other information about the code */}
    </div>
  );
};

export default ChatMessage;
