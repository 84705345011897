import React from 'react';
import './../contents.css';

const TreesExplanation: React.FC = () => {
  return (
    <div className="container">
      <h2>Trees in Programming</h2>
      <p>
        Trees are a fundamental data structure in computer science and programming. They are hierarchical structures consisting of nodes connected by edges. Each node contains a value and may have a number of child nodes. The topmost node in a tree is called the root, and nodes with no children are called leaves.
      </p>
      <h2>Characteristics of Trees</h2>
      <ul>
        <li>Hierarchy: Nodes in a tree are organized in a hierarchical manner, with each node except the root having exactly one parent node.</li>
        <li>Root: The topmost node of the tree from which all other nodes are descended.</li>
        <li>Nodes: Each node contains a value and may have zero or more child nodes.</li>
        <li>Edges: Connections between nodes represent relationships or links.</li>
        <li>Depth: The depth of a node is the number of edges from the root to that node.</li>
        <li>Height: The height of a tree is the maximum depth of any node in the tree.</li>
        <li>Binary Trees: Trees where each node has at most two children are called binary trees.</li>
        <li>Balanced Trees: Trees in which the heights of the left and right subtrees of any node differ by at most one are called balanced trees.</li>
      </ul>
      <h2>Advantages of Trees</h2>
      <ul>
        <li>Hierarchical Structure: Trees naturally represent hierarchical relationships, making them suitable for organizing data.</li>
        <li>Efficient Searching: Binary search trees offer efficient searching, insertion, and deletion operations with O(log n) time complexity on average.</li>
        <li>Ordered Data: Binary search trees maintain order among their elements, making operations like finding the minimum or maximum element easy.</li>
        <li>Hierarchical Representation: Trees are used in hierarchical data structures like file systems, organization charts, and XML/HTML documents.</li>
      </ul>
      <h2>Disadvantages of Trees</h2>
      <ul>
        <li>Complexity: Implementing and managing trees can be complex compared to simpler data structures like arrays or linked lists.</li>
        <li>Unbalanced Trees: In certain scenarios, trees may become unbalanced, leading to degradation in performance of operations.</li>
        <li>Space Overhead: Trees may consume more memory compared to other data structures due to the overhead of maintaining pointers.</li>
      </ul>
      <h2>When to Use Trees</h2>
      <ul>
        <li>When data has a hierarchical structure: Trees are ideal for representing data with a parent-child relationship, such as file systems or organizational hierarchies.</li>
        <li>When efficient searching and sorting are required: Binary search trees are efficient for searching, inserting, and deleting elements in a sorted collection.</li>
        <li>When dealing with ordered data: Trees maintain order among their elements, making them suitable for scenarios where ordered traversal is required.</li>
      </ul>
      <h2>Time and Space Complexity of Tree Operations</h2>
      <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Operation</th>
            <th>Time Complexity (Average Case)</th>
            <th>Time Complexity (Worst Case)</th>
            <th>Space Complexity</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Searching</td>
            <td>O(log n)</td>
            <td>O(n)</td>
            <td>O(1) - Iterative <br /> O(log n) - Recursive</td>
          </tr>
          <tr>
            <td>Insertion</td>
            <td>O(log n)</td>
            <td>O(n)</td>
            <td>O(1) - Iterative <br /> O(log n) - Recursive</td>
          </tr>
          <tr>
            <td>Deletion</td>
            <td>O(log n)</td>
            <td>O(n)</td>
            <td>O(1) - Iterative <br /> O(log n) - Recursive</td>
          </tr>
        </tbody>
      </table>
      </div>
      <p>These complexities are based on balanced binary search trees. For unbalanced trees, the time complexity for searching, insertion, and deletion can degrade to O(n) in the worst case, where n is the number of nodes in the tree. The space complexity for all tree operations is O(n), where n is the number of nodes in the tree, as it requires space proportional to the number of nodes to store the tree structure.</p>
      <h2>Python Code Example of a Binary Search Tree</h2>
      <pre>
        <code>
          {`class TreeNode:
    def __init__(self, value):
        self.value = value
        self.left = None
        self.right = None

class BinarySearchTree:
    def __init__(self):
        self.root = None
    
    def insert(self, value):
        if not self.root:
            self.root = TreeNode(value)
        else:
            self._insert_recursive(self.root, value)
    
    def _insert_recursive(self, node, value):
        if value < node.value:
            if node.left is None:
                node.left = TreeNode(value)
            else:
                self._insert_recursive(node.left, value)
        else:
            if node.right is None:
                node.right = TreeNode(value)
            else:
                self._insert_recursive(node.right, value)

    def search(self, value):
        return self._search_recursive(self.root, value)
    
    def _search_recursive(self, node, value):
        if node is None:
            return False
        if node.value == value:
            return True
        if value < node.value:
            return self._search_recursive(node.left, value)
        else:
            return self._search_recursive(node.right, value)`}
        </code>
      </pre>
      <h2>Trees Differences in Different Programming Languages (Tabular Format)</h2>
      <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Feature</th>
            <th>Python</th>
            <th>Java</th>
            <th>C++</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Syntax</td>
            <td>Pythonic syntax</td>
            <td>Java syntax</td>
            <td>C++ syntax</td>
          </tr>
          <tr>
            <td>Libraries</td>
            <td>Typically use built-in classes</td>
            <td>Utilize Java's `Tree` class</td>
            <td>Standard Template Library (STL)</td>
          </tr>
          <tr>
            <td>Memory Management</td>
            <td>Automatic memory management</td>
            <td>Garbage collection</td>
            <td>Manual memory management</td>
          </tr>
          <tr>
            <td>Performance</td>
            <td>Generally slower</td>
            <td>Generally faster</td>
            <td>Generally faster</td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default TreesExplanation;
