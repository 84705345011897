import React from 'react';
import './../contents.css';


const QueuesExplanation: React.FC = () => {
  return (
    <div className="container">
      <h2>What is a Queue?</h2>
      <p>
        A queue is a linear data structure that follows the First In, First Out (FIFO) principle. In other words, the element that is added first is the one that is removed first. Think of it like a queue of people waiting in line for a service: the person who arrives first is served first, and so on.
      </p>

      <h2>Characteristics of Queues</h2>
      <ul>
        <li><strong>FIFO (First In, First Out):</strong> Elements are removed in the same order they were added.</li>
        <li><strong>Insertion:</strong> Elements are added at the rear (enqueue) of the queue.</li>
        <li><strong>Deletion:</strong> Elements are removed from the front (dequeue) of the queue.</li>
        <li><strong>Restricted Access:</strong> Only the element at the front of the queue can be accessed.</li>
      </ul>

      <h2>Advantages of Queues</h2>
      <ul>
        <li><strong>Order Preservation:</strong> Maintains the order in which elements are added.</li>
        <li><strong>Simple Implementation:</strong> Easy to understand and implement.</li>
        <li><strong>Used in Many Algorithms:</strong> Widely used in algorithms such as BFS (Breadth-First Search) and job scheduling.</li>
      </ul>

      <h2>Disadvantages of Queues</h2>
      <ul>
        <li><strong>Fixed Size:</strong> Some implementations might have a fixed size, leading to overflow when the queue is full.</li>
        <li><strong>Overhead:</strong> Additional memory is required for maintaining the queue.</li>
        <li><strong>Slower Access:</strong> Accessing elements in the middle of the queue is slower compared to arrays.</li>
      </ul>

      <h2>When to Use Queues</h2>
      <ul>
        <li><strong>Task Scheduling:</strong> Managing tasks in the order they arrive.</li>
        <li><strong>Breadth-First Search (BFS):</strong> Exploring nodes level by level.</li>
        <li><strong>Resource Sharing:</strong> Sharing resources among multiple users in a fair manner.</li>
      </ul>

      <h2>Time and Space Complexity of Queues</h2>
      <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Operation</th>
            <th>Time Complexity</th>
            <th>Space Complexity</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Enqueue</td>
            <td>O(1)</td>
            <td>O(1)</td>
          </tr>
          <tr>
            <td>Dequeue</td>
            <td>O(1)</td>
            <td>O(1)</td>
          </tr>
          <tr>
            <td>Peek</td>
            <td>O(1)</td>
            <td>O(1)</td>
          </tr>
          <tr>
            <td>Size</td>
            <td>O(1)</td>
            <td>O(1)</td>
          </tr>
        </tbody>
      </table>
      </div>

      <h2>Python Example of a Queue</h2>
      <pre><code>
      {`class Queue:
    def __init__(self):
        self.queue = []

    def enqueue(self, item):
        self.queue.append(item)

    def dequeue(self):
        if not self.is_empty():
            return self.queue.pop(0)
        else:
            return "Queue is empty"

    def is_empty(self):
        return len(self.queue) == 0

    def size(self):
        return len(self.queue)

# Example usage
q = Queue()
q.enqueue(1)
q.enqueue(2)
q.enqueue(3)
print("Queue size:", q.size())  # Output: 3
print("Dequeued item:", q.dequeue())  # Output: 1
print("Queue size after dequeue:", q.size())  # Output: 2`}
      </code></pre>

      <h2>Queue Differences in Different Programming Languages (Tabular Format)</h2>
      <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Feature</th>
            <th>Python</th>
            <th>Java</th>
            <th>C++</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>Library</strong></td>
            <td>Built-in (`collections.deque`)</td>
            <td>`java.util.Queue` interface</td>
            <td>`std::queue` container</td>
          </tr>
          <tr>
            <td><strong>Syntax</strong></td>
            <td>Queue implemented using a list</td>
            <td>Queue is an interface, often implemented using `LinkedList` or `ArrayDeque`</td>
            <td>Queue implemented using `std::queue`</td>
          </tr>
          <tr>
            <td><strong>Operations</strong></td>
            <td>`enqueue`, `dequeue`, `is_empty`, `size`</td>
            <td>`offer`, `poll`, `isEmpty`, `size`</td>
            <td>`push`, `pop`, `empty`, `size`</td>
          </tr>
          <tr>
            <td><strong>Implementation</strong></td>
            <td>Pure Python implementation</td>
            <td>Interface implemented by various classes</td>
            <td>Standard template library implementation</td>
          </tr>
          <tr>
            <td><strong>Complexity</strong></td>
            <td>Access: O(n), Insert/Delete: O(1)</td>
            <td>Access: O(n), Insert/Delete: O(1) (Amortized)</td>
            <td>Access: O(n), Insert/Delete: O(1)</td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default QueuesExplanation;
