import React from 'react';
import Navbar from '../components/home/NavBar';
import ChatInterface from '../components/chat/chat-page';
import './../App.css';

const HomePage: React.FC = () => {

  return (
    <div className="App">
      <Navbar />
      <div className="main-content">
        <ChatInterface />
      </div>
      
    </div>
  );
};

export default HomePage;