// CustomMessage.tsx
import React from 'react';

const CustomMessage: React.FC = () => {
  return (
    <div className="custom-message">
    <div className="box-wrapper">
    <h2 className='greetings'>What coding problem can I help solve today?</h2>
    <p className='greetings-small-text'>Most responses are factual and efficient code, unlike ChatGPT's pattern generation.</p>
    <div className="sub-text-box">
      <p className='sub-text'>
        Primarily focus on LeetCode questions, but I've got you covered on all problems!
      </p>
      <p className='small-sub-text'> What I will do for you:</p>
      <ul className="list-items">
        <li>Provide the code to your problem</li>
        <li>Explain the code</li>
        <li>Provide time complexity</li>
        <li>Provide space complexity</li>
        {/* Add more list items as needed */}
      </ul>
    </div>
    </div>
  </div>
  );
};

export default CustomMessage;
