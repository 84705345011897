// ChatInterface.tsx
import React, { useRef, useState } from 'react';
import './Chatpage.css'; // Your CSS file for chat interface styling
import ChatMessage from './chat-message';
import CustomMessage from '../home/CustomMessage';
import LoadingIndicator from './LoadingIndicator';

const ChatInterface: React.FC = () => {
  const dropdown1Ref = useRef<HTMLSelectElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isScrollable, setIsScrollable] = useState<boolean>(false);
  const [messages, setMessages] = useState<any[]>([]);
  const [showCustomMessage, setShowCustomMessage] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  

  const handleReload = () => {
    window.location.reload(); // Reload the page when the button is clicked
  };
/*
  useEffect(() => {
    console.log('isLoading updated:', isLoading);
  }, [isLoading]);
*/

  const handleInputChange = () => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';

      setIsScrollable(textarea.scrollHeight > 100); // Set the max-height threshold here
    }
  };

  const handleSend = async () => {
    if (dropdown1Ref.current && textareaRef.current) {
      const dropdown1 = dropdown1Ref.current;

      const textarea = textareaRef.current;

      if (dropdown1.value && textarea.value.trim() !== '') {
        setShowCustomMessage(false);
        setError(null)
        setMessages([])
        setIsLoading(true);
        textarea.value = textarea.value.replace(/\s+/g, ' ').trim();
        const requestData = {
          language: dropdown1.value,
          question: textarea.value.trim(),
        };
        try {
          const response = await fetch('/api/get-solution', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
          });
          
          if (response.ok) {
            const data = await response.json();
            // Update messages state with data received from the API
            
            //setMessages([...messages, data]);
            setMessages([data]);
          } else {
            // Handle API error cases
            console.error('Failed to fetch messages');
          }
        } catch (error) {
          console.error('Error fetching messages:', error);
          setError('An unexpected error occurred. Please try again.');
        } finally {
          
            setIsLoading(false); // Set isLoading back to false after the API call is completed
        }

        // Clear textarea after sending
        textarea.value = '';
        textarea.style.height = 'auto';
        setIsScrollable(false);
      } else {
        if (!dropdown1.value){
          alert('Please select programming language.');
        }
        if (!textarea.value.trim()){
          alert('Please type or paste your question.');

        }
      }
    }
  };
  return (
    <div className="chat-container">
      <div className="dropdowns">
        <select ref={dropdown1Ref}>
          <option value="">Select Programming Language</option>
          <option value="python3">python3</option>
          <option value="java">java</option>
          <option value="cpp">c++</option>
          <option value="javascript">javascript</option>
          <option value="c">c</option>
          <option value="csharp">c#</option>
          {/* Add more options for the first dropdown */}
          {/* ... */}
        </select>
        <button onClick={handleReload}>New Question</button>
      </div>
      <div className="textarea-wrapper">
        <div className={`input-area ${isScrollable ? 'scrollable' : ''}`} style={{ maxHeight: isScrollable ? '100px' : '40px' }}>
          <textarea
            ref={textareaRef}
            placeholder="Type or paste your question..."
            rows={1}
            onChange={handleInputChange}
            style={{ maxHeight: isScrollable ? '100px' : 'inherit', overflowY: isScrollable ? 'auto' : 'hidden' }}
          />
          <button className="send-button" onClick={handleSend}>Send</button>
        </div>
      </div>
      <div className="chat-box">
        {/* Chat messages will appear here */}
        {/* Example message */}
        <div className="message">
          {showCustomMessage && !isLoading && <CustomMessage />}
          {!showCustomMessage && isLoading && <LoadingIndicator />}
          {error && <p className="error-message">{error}</p>}
          {messages.map((message, index) => (
            <div key={index} className="message-content">
              <div className="message-wrapper">
                <ChatMessage message={message} />
              </div>
            </div>
          ))}
        </div>
        {/* Add more messages as needed */}
      </div>
    </div>
  );
};

export default ChatInterface;
