import React from 'react';
import { data_struture_components, ComponentData } from '../../constants/data-structures/data-structure-object';
import { algorithms_components, AlgoComponentData } from '../../constants/algorithms/algorithms-object';
interface Props {
  dataType: string; 
  blogType: string;
}

const BlogsDynamic: React.FC<Props> = ({ dataType, blogType }) => {
  let blog_type_components;
  if (blogType === 'data_structure') {
    blog_type_components = data_struture_components ;
  } else if (blogType === 'algorithm') {
    blog_type_components = algorithms_components;
  } // Add more conditions for other blog types as needed

  const componentInfo: ComponentData | AlgoComponentData |undefined = blog_type_components?.[dataType]?.info;
  const Component: React.FC | undefined = blog_type_components?.[dataType]?.component;

  if (!Component || !componentInfo) {
    return <div>No component found for the provided data type.</div>;
  }

  return (
    <div>
      <p className='blogTitle'>{componentInfo.title}</p>
      <Component />
    </div>
  );
};

export default BlogsDynamic;
