import React from 'react';
import './../contents.css';

const TupleExplanation: React.FC = () => {
    return (
        <div className="container">
            <h2>Tuples in Programming</h2>
            <p>A tuple is an immutable sequence of heterogeneous elements, meaning once created, it cannot be modified (immutable), and it can contain elements of different data types. Tuples are commonly used for grouping related data. They are supported in various programming languages including Python, Java, and Swift.</p>

            <h2>Characteristics of Tuples</h2>
            <ul>
                <li><strong>Immutable:</strong> Once a tuple is created, its elements cannot be changed, added, or removed.</li>
                <li><strong>Ordered:</strong> Elements in a tuple maintain a specific order, meaning they can be accessed by index.</li>
                <li><strong>Heterogeneous:</strong> Tuples can contain elements of different data types.</li>
                <li><strong>Iterable:</strong> Tuples can be iterated over using loops.</li>
                <li><strong>Hashable:</strong> Tuples are hashable if all their elements are hashable, making them suitable as keys in dictionaries.</li>
            </ul>
            
            <h2>Advantages of Tuples</h2>
            <ul>
                <li><strong>Faster Access:</strong> Tuples offer faster access compared to lists because they are stored in a contiguous memory block.</li>
                <li><strong>Safe Data:</strong> Since tuples are immutable, they provide data integrity, ensuring that the data remains unchanged.</li>
                <li><strong>Hashability:</strong> Tuples can be used as keys in dictionaries due to their hashable nature.</li>
            </ul>
            
            <h2>Disadvantages of Tuples</h2>
            <ul>
                <li><strong>Immutability:</strong> While immutability ensures data integrity, it also means that any operation that requires modification of elements necessitates creating a new tuple, which can be inefficient for large tuples.</li>
                <li><strong>Limited Functionality:</strong> Tuples lack some of the features of lists, such as append(), remove(), and sort(), which can limit their flexibility in certain scenarios.</li>
            </ul>
            
            <h2>When to Use Tuples</h2>
            <ul>
                <li><strong>Fixed Collection:</strong> When you have a fixed collection of elements that will not change during the program execution.</li>
                <li><strong>Data Integrity:</strong> When you want to ensure that the data remains unchanged.</li>
                <li><strong>Hashable Keys:</strong> When you need to use the collection as keys in dictionaries.</li>
                <li><strong>Memory Efficiency:</strong> When you need a more memory-efficient data structure compared to lists.</li>
            </ul>
            
            <h2>Time and Space Complexity of Tuple Operations (in tabular form)</h2>
            <div className="table-container">
            <table>
                <thead>
                    <tr>
                        <th>Operation</th>
                        <th>Time Complexity</th>
                        <th>Space Complexity</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Access by index</td>
                        <td>O(1)</td>
                        <td>O(1)</td>
                    </tr>
                    <tr>
                        <td>Search</td>
                        <td>O(n)</td>
                        <td>O(1)</td>
                    </tr>
                    <tr>
                        <td>Insertion</td>
                        <td>N/A</td>
                        <td>N/A</td>
                    </tr>
                    <tr>
                        <td>Deletion</td>
                        <td>N/A</td>
                        <td>N/A</td>
                    </tr>
                    <tr>
                        <td>Length</td>
                        <td>O(1)</td>
                        <td>O(1)</td>
                    </tr>
                </tbody>
            </table>
            </div>
            <h2>Example in Python</h2>
            <pre>
                <code>
{`
# Creating a tuple
my_tuple = (1, 'hello', 3.14)

# Accessing elements
print(my_tuple[0])  # Output: 1
print(my_tuple[1])  # Output: hello

# Iterating over a tuple
for item in my_tuple:
    print(item)

# Trying to modify a tuple (will result in an error)
my_tuple[0] = 2  # TypeError: 'tuple' object does not support item assignment
`}
                </code>
            </pre>
        
        </div>
    );
}

export default TupleExplanation;
