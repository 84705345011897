import React from 'react';
import './contents.css';
import Navbar from '../components/home/NavBar';
import Footer from '../components/home/Footer';

const AboutPage: React.FC = () => {
    return (
        <div className="App">
        <Navbar />
        
      <div className="container">
        <h1>Welcome to LiteCodeSolver!</h1>
        <p>
            At LiteCodeSolver, we are passionate about programming, algorithms, and problem-solving. Our mission is to provide a comprehensive platform for both beginners and seasoned developers to enhance their coding skills, understand fundamental data structures and algorithms, and excel in technical interviews.
        </p>

        <h2>What We Offer:</h2>
        <ol>
            <li>
            <strong>Code Solution:</strong> Need a quick solution to a coding problem? Our website provide code snippets for a wide range of programming challenges. We are here to provide solutions to your LeetCode problems. Our
            coding solutions are mostly factual and efficient, unlike LLM models pattern generation. Whether you're practicing for a coding interview or tackling a personal project, we are here to help you.
            </li>
            <li>
            <strong>Algorithm Explanations:</strong> Understanding the logic behind algorithms is key to mastering programming. We provide detailed explanations for various algorithms, breaking down complex concepts into easy-to-understand explanations. From sorting algorithms to graph traversal techniques, we've got you covered.
            </li>
            <li>
            <strong>Time and Space Complexity Analysis:</strong> Efficiency matters in coding. We delve into the time and space complexities of algorithms, helping you optimize your code for better performance. Learn how to analyze algorithms and make informed decisions about which approach to use in different scenarios.
            </li>
            <li>
            <strong>Data Structures Demystified:</strong> Data structures form the backbone of computer science. Explore our pages dedicated to different data structures, including arrays, linked lists, trees, graphs, and more. Gain insights into their properties, operations, and practical applications through clear explanations and illustrative examples.
            </li>
        </ol>

        <h2>Our Commitment:</h2>
        <p>
            At LiteCodeSolver!, we are committed to fostering a supportive learning environment. Whether you're a novice coder taking your first steps or an experienced developer seeking to deepen your understanding, our resources are designed to cater to your needs. We believe that everyone has the potential to become a proficient coder with the right guidance and practice.
        </p>

        <h2>Get Started Today:</h2>
        <p>
            Ready to embark on your coding journey? Dive into our wealth of resources and start honing your programming skills today. Whether you're preparing for a technical interview, expanding your knowledge of data structures, or simply looking to sharpen your coding abilities, LiteCodeSolver is here to support you every step of the way.
        </p>

        <p>Happy Coding!</p>
        <p>LiteCodeSolver</p>
    </div>
    <Footer/>
    </div>
  );
}

export default AboutPage;
