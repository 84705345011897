// componentsData.ts

import React from 'react';
import ArraysExplanation from './explanations/ArraysExplanation';
import MapsExplanation from './explanations/MapsExplanation';
import SetsExplanation from './explanations/SetsExplanation';
import LinkedListsExplanation from './explanations/LinkedListsExplanation';
import StacksExplanation from './explanations/StacksExplanation';
import QueuesExplanation from './explanations/QueuesExplanation';
import TreesExplanation from './explanations/TreesExplanation';
import GraphsExplanation from './explanations/GraphsExplanation';
import TrieExplanation from './explanations/TrieExplanation';
import HeapsExplanation from './explanations/HeapsExplanation';
import TupleExplanation from './explanations/TuplesExplanation';

export interface ComponentData {
  title: string;
  description: string;
}

export const data_struture_components: Record<string, { component: React.FC, info: ComponentData }> = {
  Array: {
    component: ArraysExplanation,
    info: {
      title: 'What is Array or List?',
      description: 'This explains array in different programming language'
    }
  },
  Maps: {
    component: MapsExplanation,
    info: {
      title: 'What is Maps or Dictionary?',
      description: 'This explains maps/dictionaries in different programming language'
    }
  },
  Sets: {
    component: SetsExplanation,
    info: {
      title: 'What is Sets?',
      description: 'This explains Sets in different programming language'
    }
  }, 
  LinkedList: {
    component: LinkedListsExplanation,
    info: {
      title: 'What is Linked List?',
      description: 'This explains LinkedList in different programming language'
    }
  },
  Stacks: {
    component: StacksExplanation,
    info: {
      title: 'What is Stacks?',
      description: 'This explains Stacks in different programming language'
    }
  },
  Queue: {
    component: QueuesExplanation,
    info: {
      title: 'What is Queue?',
      description: 'This explains Queue in different programming language'
    }
  },
  Trees: {
    component: TreesExplanation,
    info: {
      title: 'What is Tree?',
      description: 'This explains Tree in different programming language'
    }
  },
  Graphs: {
    component: GraphsExplanation,
    info: {
      title: 'What is Graph?',
      description: 'This explains Graphs in different programming language'
    }
  },
  Tries: {
    component: TrieExplanation,
    info: {
      title: 'What is Trie?',
      description: 'This explains Tries in different programming language'
    }
  },
  Heaps: {
    component: HeapsExplanation,
    info: {
      title: 'What is Heap?',
      description: 'This explains Heaps in different programming language'
    }
  },
  Tuples: {
    component: TupleExplanation,
    info: {
      title: 'What is Tuple?',
      description: 'This explains Tuple in different programming language'
    }
  },
};
