import React, { useState } from 'react';
import { subMenuItems } from '../../constants/constant';
import './../../App.css';

const Navbar: React.FC = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [isDataStructuresSubMenuOpen, setIsDataStructuresSubMenuOpen] = useState<boolean>(false);
  const [isAlgorithmSubMenuOpen, setIsAlgorithmSubMenuOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    setShowMenu(prevState => !prevState);
  };

  const toggleDataStructuresSubMenu = () => {
    setIsDataStructuresSubMenuOpen(prevState => !prevState);
  };

  const toggleAlgorithmSubMenu = () => {
    setIsAlgorithmSubMenuOpen(prevState => !prevState);
  };

  const renderSubMenuItems = (key: string) => {
    let blogType: string;
    if (key === 'data_structures') {
      blogType = 'data_structure'
    }
    else if (key === 'algorithm') {
      blogType = 'algorithm'
    }
    const categoryItems = subMenuItems[key];
    if (!categoryItems) return null; // Return null if key doesn't exist
    return (
      <ul className={`sub-menu-row ${key === 'data_structures' ? (isDataStructuresSubMenuOpen ? 'show' : '') : (isAlgorithmSubMenuOpen ? 'show' : '')}`}>
        {categoryItems.map((item, index) => (
          <li key={index}>
            <a href={`/blogs/${blogType}/${item.label}`}>{item.label}</a>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="navbar">
      <h1 className="navbar-title">
        LitcodeSolver
        <img src="/LS.png" alt=''  className="title-image" />
      </h1>
      <div className="menu-toggle" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <ul className={`menu ${showMenu ? 'show' : ''}`}>
        <button className='close-menu' onClick={toggleMenu}>X</button>
        <li className="menu-item"><a href="/">Home</a></li>
        <li className={`menu-item ${isDataStructuresSubMenuOpen || isAlgorithmSubMenuOpen ? 'open-sub-menu' : ''}`}>
  <a href="#!">Data Structures</a>
  <button className='close-submenu' onClick={toggleDataStructuresSubMenu}>{isDataStructuresSubMenuOpen ? '▲' : '▼'}</button>
  <div className="sub-menu">
    {renderSubMenuItems('data_structures')} {/* Pass the key here */}
  </div>
</li>

        <li className={`menu-item ${isDataStructuresSubMenuOpen || isAlgorithmSubMenuOpen ? 'open-sub-menu' : ''}`}>
          <a href="#!">Algorithms</a>
          <button className='close-submenu' onClick={toggleAlgorithmSubMenu}>{isAlgorithmSubMenuOpen ? '▲' : '▼'}</button>
          <div className="sub-menu">
            {renderSubMenuItems('algorithm')} {/* Pass the key here */}
          </div>
        </li>
        <li className="menu-item"><a href="/about">About</a></li>
        <li className="menu-item"><a href="/contact">Contact</a></li>
      </ul>
    </div>
  );
};

export default Navbar;
