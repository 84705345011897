import React from 'react';
import './../contents.css';

const TwoPointerExplanation : React.FC = () => {
    return (
        <div className="container">
            <h2>Two-Pointer Algorithm</h2>
            <p>
                The Two-Pointer algorithm is a technique used to solve problems involving arrays or linked lists where you need to find a solution by iterating over the elements using two pointers simultaneously. This approach is particularly useful when dealing with sorted arrays or lists, as it allows you to efficiently search, manipulate, or compare elements without using nested loops.
            </p>
            <h2>Steps in Using Two-Pointer Algorithm</h2>
            <ol>
                <li><strong>Initialize Pointers:</strong> Start by initializing two pointers, usually at the beginning and end of the array or list.</li>
                <li><strong>Move Pointers:</strong> Iterate through the array or list, moving the pointers according to the problem requirements.</li>
                <li><strong>Process:</strong> Perform operations, comparisons, or modifications based on the values pointed by the pointers.</li>
                <li><strong>Repeat or Terminate:</strong> Continue moving the pointers until the desired condition is met or terminate when the pointers cross each other.</li>
            </ol>
            <h2>When to Use Two-Pointer Algorithm</h2>
            <ul>
                <li><strong>Searching:</strong> When you need to find a pair of elements that satisfy certain conditions.</li>
                <li><strong>Optimization:</strong> When you want to improve the time complexity by avoiding nested loops.</li>
                <li><strong>Two-Sequence Processing:</strong> When you need to compare or process elements from two different sequences.</li>
            </ul>
            <h2>Data Structure for Two-Pointer Algorithm</h2>
            <p>Arrays or linked lists are commonly used with the two-pointer technique. For problems requiring searching, sorting, or comparing elements, arrays are preferable due to their random access capability.</p>
            <h2>Common Problems Solved Using Two-Pointer Technique</h2>
            <ul>
                <li><strong>Two Sum:</strong> Finding two numbers in a sorted array that sum up to a target value.</li>
                <li><strong>Merge Sorted Array:</strong> Merging two sorted arrays into one sorted array in-place.</li>
                <li><strong>Palindrome Detection:</strong> Checking if a string is a palindrome by comparing characters from both ends simultaneously.</li>
                <li><strong>Intersection of Two Sorted Arrays:</strong> Finding common elements between two sorted arrays.</li>
            </ul>
            <h2> Two-Sum problem using the two-pointer technique (python)</h2>
            <pre><code>{`def two_sum(nums, target):
    left, right = 0, len(nums) - 1
    while left < right:
        curr_sum = nums[left] + nums[right]
        if curr_sum == target:
            return [left, right]
        elif curr_sum < target:
            left += 1
        else:
            right -= 1
    return []

# Example usage:
nums = [2, 7, 11, 15]
target = 9
print(two_sum(nums, target))  # Output: [0, 1]`}</code></pre>

            <p>Explanation:

                We initialize two pointers, left at the beginning of the array and right at the end.
                We iterate through the array, calculating the sum of elements pointed by left and right.
                If the sum equals the target, we return the indices.
                If the sum is less than the target, we increment left to increase the sum.
                If the sum is greater than the target, we decrement right to decrease the sum.
                </p>
            <ul>
                <li><strong>Time Complexity:</strong> The time complexity of the two-pointer algorithm is typically O(n), where n is the size of the array or list.</li>
                <li><strong>Space Complexity:</strong> The space complexity is O(1) since the algorithm uses only a constant amount of extra space.</li>
            </ul>
        </div>
    );
}

export default TwoPointerExplanation;
