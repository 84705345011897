import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import BlogsDynamic from '../components/blog/BlogsDynamic';
import Navbar from '../components/home/NavBar';
import Footer from '../components/home/Footer';
import './../App.css';

type RouteParams = {
  dataType?: string; // Make the dataType optional
}

const Blogs: React.FC = () => {
  const { dataType } = useParams<RouteParams>(); // Destructure dataType from useParams
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const blogType = pathSegments[2];

  if (!dataType) {
    return <div>Data type not provided</div>; // Handle the case where dataType is undefined
  }

  return (
    <div>
      <Navbar/>
      <BlogsDynamic dataType={dataType} blogType = {blogType} /> {/* Render DynamicComponent with the dataType from the URL */}
      <Footer/>
    </div>
  );
};

export default Blogs;
