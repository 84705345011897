import React from 'react';
import './../contents.css';

const StacksExplanation: React.FC = () => {
    return (
        <div className="container">
            <h2>Stacks in Programming</h2>
            <p>
                A stack is a linear data structure that follows the Last In, First Out (LIFO) principle. It means that the element which is inserted last will be the first one to be removed. Think of it as a stack of plates - you add plates on top and remove them from the top.
            </p>
            <h2>Characteristics of Stacks</h2>
            <ul>
                <li><strong>LIFO Structure:</strong> The last element added to the stack is the first one to be removed.</li>
                <li><strong>Operations:</strong> Stacks typically support two main operations: push (to add an element) and pop (to remove the top element).</li>
                <li><strong>Restricted Access:</strong> Elements can only be added or removed from the top of the stack.</li>
                <li><strong>Limited Capacity:</strong> Stacks often have a fixed size or a dynamic size limit imposed by the underlying data structure.</li>
                <li><strong>Commonly Implemented using Arrays or Linked Lists:</strong> Stacks can be implemented using arrays (static or dynamic) or linked lists.</li>
            </ul>

            <h2>Advantages of Stacks</h2>
            <ol>
                <li>
                    <strong>Simplicity and Ease of Implementation:</strong> Stacks are straightforward to understand and implement, making them accessible for both beginner and experienced programmers.
                </li>
                <li>
                    <strong>Clear Data Management:</strong> Stacks provide a clear and concise way to manage data where the order of access is important.
                </li>
                <li>
                    <strong>Useful in Algorithmic Solutions:</strong> Stacks are fundamental in many algorithms and data processing tasks.
                </li>
            </ol>

            <h2>Disadvantages of Stacks</h2>
            <ol>
                <li>
                    <strong>Limited Functionality:</strong> Stacks have limited functionality compared to other data structures like queues.
                </li>
                <li>
                    <strong>Memory Usage Efficiency:</strong> Depending on the implementation, stacks can be less memory-efficient compared to other data structures for certain operations.
                </li>
                <li>
                    <strong>Restricted Access:</strong> Stacks only allow access to the top element, which can be a limitation in situations where access to other elements is necessary.
                </li>
            </ol>

            <h2>When to Use Stacks</h2>
            <ol>
                <li><strong>Reversing Data:</strong>
                    <p>Stacks are commonly used when you need to reverse the order of elements. For example, if you have a sequence of elements and you need to reverse their order, you can push them onto a stack and then pop them off to get them in the reversed order.</p>
                </li>
                <li><strong>Parsing Expressions:</strong>
                    <p>Stacks are frequently used in parsing algorithms, particularly for converting infix expressions to postfix (or prefix) notation. During parsing, operators and operands are pushed onto the stack in a specific order, and then popped off in a different order to obtain the desired expression format.</p>
                </li>
                <li><strong>Undo Mechanisms in Software Applications:</strong>
                    <p>Stacks are useful for implementing undo mechanisms in software applications. Each action performed by the user can be pushed onto a stack, and when the user requests an undo operation, the actions are popped off the stack in reverse order, effectively undoing them.</p>
                </li>
                <li><strong>Handling Function Calls and Recursion:</strong>
                    <p>Stacks play a crucial role in managing function calls and recursion in programming languages. When a function is called, its context (including local variables and return address) is typically pushed onto the call stack. When the function completes execution, its context is popped off the stack, returning control to the caller. Recursion relies on the call stack to manage nested function calls. Each recursive call pushes a new frame onto the stack, allowing the function to execute until the base case is reached.</p>
                </li>
                <li><strong>Backtracking Algorithms:</strong>
                    <p>Stacks are often used in backtracking algorithms, such as depth-first search (DFS). During exploration of a search space, the state of the system is pushed onto the stack at each decision point. If a dead end is encountered, the algorithm backtracks by popping states off the stack until a viable alternative is found.</p>
                </li>
                <li><strong>Managing History in Web Browsers:</strong>
                    <p>Stacks are employed in web browsers to manage the history of visited web pages. Each time a user navigates to a new page, the URL and other relevant information are pushed onto a stack. The user can then navigate backward by popping items off the stack.</p>
                </li>
            </ol>

            <h2>Code Example of Stacks in Python</h2>
            <pre><code>{`
class Stack:
    def __init__(self):
        self.stack = []

    def push(self, item):
        self.stack.append(item)

    def pop(self):
        if not self.is_empty():
            return self.stack.pop()
        else:
            return None

    def is_empty(self):
        return len(self.stack) == 0

    def peek(self):
        if not self.is_empty():
            return self.stack[-1]
        else:
            return None

# Example usage
stack = Stack()
stack.push(1)
stack.push(2)
stack.push(3)
print("Stack:", stack.stack)
print("Peek:", stack.peek())
print("Pop:", stack.pop())
print("Stack after pop:", stack.stack)
            `}
            </code></pre>

            <h2>Stacks in Different Programming Languages</h2>
            <div className="table-container">
            <table>
                <thead>
                    <tr>
                        <th>Language</th>
                        <th>Stack Implementation</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Python</td>
                        <td>Can be implemented using lists (<code className='table-code'>[]</code>) or built-in <code className='table-code'>deque</code> module.</td>
                    </tr>
                    <tr>
                        <td>C/C++</td>
                        <td>Typically implemented using arrays or linked lists.</td>
                    </tr>
                    <tr>
                        <td>Java</td>
                        <td>Implemented using the <code className='table-code'>java.util.Stack</code> class or <code className='table-code'>Deque</code> interface.</td>
                    </tr>
                    <tr>
                        <td>JavaScript</td>
                        <td>Can be implemented using arrays or as part of the standard library.</td>
                    </tr>
                    <tr>
                        <td>Ruby</td>
                        <td>Implemented using arrays or as part of the standard library.</td>
                    </tr>
                    <tr>
                        <td>C#</td>
                        <td>Implemented using the <code className='table-code'>System.Collections.Stack</code> class.</td>
                    </tr>
                </tbody>
            </table>
            </div>
        </div>
    );
};

export default StacksExplanation;
