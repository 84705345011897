import React from 'react';
import './../contents.css';

const BFSExplanation : React.FC = () => {
    return (
        <div className="container">
            <h2>Breadth-First Search (BFS) Algorithm</h2>
            <p>
                Breadth-first search (BFS) is a graph traversal algorithm that explores nodes in a level-by-level manner,
                starting from a designated source node. It is commonly used to find the shortest path between two nodes
                in an unweighted graph, to visit all the nodes in a graph, or to solve problems that require exploring
                all possible states or configurations in a search space. BFS is also applicable to trees, which can be
                considered a special case of graphs.
            </p>
            <h2>Steps of BFS Algorithm</h2>
            <ol>
                <li><strong>Start:</strong> Choose a starting node and enqueue it in a queue.</li>
                <li><strong>Explore:</strong> While the queue is not empty, dequeue a node and explore all its adjacent nodes.</li>
                <li><strong>Enqueue:</strong> Enqueue each adjacent node that hasn't been visited yet and mark it as visited.</li>
                <li><strong>Repeat:</strong> Continue until all reachable nodes have been visited.</li>
            </ol>
            <h2>When to Use BFS</h2>
            <ul>
                <li>When you need to find the shortest path in an unweighted graph.</li>
                <li>When you need to visit all the nodes in a graph or tree.</li>
                <li>When you need to explore all possible states or configurations in a search space.</li>
                <li>When the graph is relatively small or sparse, as BFS may consume more memory than depth-first search (DFS).</li>
            </ul>
            <h2>Data Structure for BFS:</h2>
            <ul>
                <li>Queue: BFS uses a queue to keep track of nodes to visit next. This ensures that nodes are explored in the order they were discovered.</li>
            </ul>
            <h2>Common Problems Solved Using BFS</h2>
            <ul>
                <li>Finding the shortest path between two nodes in an unweighted graph.</li>
                <li>Finding all connected components in a graph.</li>
                <li>Testing for bipartiteness of a graph.</li>
                <li>Finding the shortest path in a maze.</li>
            </ul>
            <h2>Python Code Example:</h2>
            <pre>
                <code>
                    {`from collections import deque

def bfs(graph, start):
    visited = set()
    queue = deque([start])
    visited.add(start)

    while queue:
        node = queue.popleft()
        print(node)  # Process the node, e.g., print it

        for neighbor in graph[node]:
            if neighbor not in visited:
                queue.append(neighbor)
                visited.add(neighbor)

# Example usage:
graph = {
    'A': ['B', 'C'],
    'B': ['A', 'D', 'E'],
    'C': ['A', 'F'],
    'D': ['B'],
    'E': ['B', 'F'],
    'F': ['C', 'E']
}

print("BFS Traversal:")
bfs(graph, 'A')`}
                </code>
            </pre>
            <p>
                In this code:
                <ul>
                    <li>graph represents the adjacency list of the graph.</li>
                    <li>The bfs function takes the graph and a starting node as input and performs BFS traversal.</li>
                    <li>It uses a set visited to keep track of visited nodes and a queue queue to keep track of nodes to visit next.</li>
                    <li>Time Complexity: O(V + E), where V is the number of vertices and E is the number of edges.</li>
                    <li>Space Complexity: O(V), where V is the number of vertices, due to the space required for the queue and the visited set.</li>
                </ul>
            </p>
        </div>
    );
};

export default BFSExplanation;
