import React from 'react';
import './../contents.css';


const SetsExplanation: React.FC = () => {
  return (
    <div className="container">
      <h2>Sets in Programming</h2>
      <p>
        Set is a data structure that stores a collection of unique elements. Unlike lists or arrays, sets do not allow duplicate elements. Sets are commonly used when you need to store a collection of items without caring about the order and without allowing duplicate values.
      </p>
      <h2 className="subheading">Key Features of Sets</h2>
      <ol>
        <li><strong>Unique Elements:</strong> Sets ensure that each element is unique. If you attempt to add an element that already exists in the set, it won't be added again.</li>
        <li><strong>Unordered:</strong> The elements in a set are not stored in any particular order. This means you cannot access elements by index, and there's no inherent order to the elements.</li>
        <li><strong>Mutable or Immutable:</strong> Sets can be mutable or immutable depending on the programming language. Mutable sets allow you to add or remove elements, while immutable sets do not allow modifications after creation.</li>
      </ol>
      <h2 className="subheading">Advantages of Sets</h2>
      <ul>
        <li><strong>Fast Lookup:</strong> Sets offer constant time complexity O(1) for operations like membership testing (checking if an element is in the set).</li>
        <li><strong>Removal of Duplicates:</strong> Sets automatically remove duplicate elements, simplifying code and ensuring data integrity.</li>
        <li><strong>Mathematical Operations:</strong> Sets support common mathematical operations such as union, intersection, and difference, which can be useful for tasks like data manipulation and analysis.</li>
      </ul>
      <h2 className="subheading">Disadvantages of Sets</h2>
      <ul>
        <li><strong>Unordered:</strong> While unordered nature is advantageous in many scenarios, it might be a disadvantage if you need to maintain the order of elements.</li>
        <li><strong>No Indexing:</strong> Sets do not support indexing, so you cannot access elements by their position. This limitation can be problematic in situations where element order matters.</li>
      </ul>
      <h2 className="subheading">When to Use Sets</h2>
      <ul>
        <li><strong>Removing Duplicates:</strong> When you need to remove duplicate elements from a collection.</li>
        <li><strong>Checking Membership:</strong> When you frequently need to check whether an element is present in a collection.</li>
        <li><strong>Performing Set Operations:</strong> When you need to perform set operations like union, intersection, or difference between collections.</li>
      </ul>

      <h2 className="subheading">Sets Code Example in Python</h2>
      <pre>
        <code>
{`
  // Python sets code example
  
  # Creating a set
  my_set = {1, 2, 3, 4, 5}
  
  # Adding elements to a set
  my_set.add(6)
  my_set.add(7)
  
  # Removing elements from a set
  my_set.remove(3)
  
  # Checking membership
  print(5 in my_set)  # Output: True
  print(10 in my_set) # Output: False
  
  # Set operations
  set1 = {1, 2, 3}
  set2 = {3, 4, 5}
  
  union_set = set1.union(set2)
  intersection_set = set1.intersection(set2)
  difference_set = set1.difference(set2)
  
  print("Union:", union_set)
  print("Intersection:", intersection_set)
  print("Difference:", difference_set)
`}
        </code>
      </pre>

      <h2 className="subheading">Sets in Different Programming Languages</h2>
      <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Language</th>
            <th>Set Implementation</th>
            <th>Mutable?</th>
            <th>Operations</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Python</td>
            <td>`set`, `frozenset`</td>
            <td>Mutable (`set`), Immutable (`frozenset`)</td>
            <td>Union, Intersection, Difference, Membership testing</td>
          </tr>
          <tr>
            <td>Java</td>
            <td>`HashSet`, `LinkedHashSet`, `TreeSet`</td>
            <td>Mutable (`HashSet`, `LinkedHashSet`), Immutable (`TreeSet`)</td>
            <td>Union, Intersection, Difference, Membership testing</td>
          </tr>
          <tr>
            <td>C++</td>
            <td>`std::set`</td>
            <td>Mutable</td>
            <td>Union, Intersection, Difference, Membership testing</td>
          </tr>
          <tr>
            <td>JavaScript</td>
            <td>`Set`</td>
            <td>Mutable</td>
            <td>Addition, Deletion, Membership testing</td>
          </tr>
          <tr>
            <td>C#</td>
            <td>`HashSet`, `SortedSet`</td>
            <td>Mutable (`HashSet`), Immutable (`SortedSet`)</td>
            <td>UnionWith, IntersectWith, ExceptWith, IsSubsetOf, IsSupersetOf, Overlaps</td>
          </tr>
        </tbody>
      </table>
      </div>
      <p>Each programming language may have its own implementation details and additional features for sets, but the core principles remain consistent across languages.</p>
    </div>
  );
}

export default SetsExplanation;
