import React from 'react';
import './../contents.css';

const DFSExplanation : React.FC = () => {
    return (
        <div className="container">
                <h2>Depth-first search (DFS)</h2>
                <p>
                    Depth-first search (DFS) is a fundamental algorithm used to traverse or search tree or graph data structures. It starts at a designated node (often called the "root" in trees or "source" in graphs) and explores as far as possible along each branch before backtracking. DFS can be implemented recursively or iteratively, and it's used for a variety of tasks like finding connected components, detecting cycles, and topological sorting.
                </p>

                <h2>Steps of DFS</h2>
                <ol>
                    <li><strong>Start at a designated node:</strong> Begin traversal from a starting node.</li>
                    <li><strong>Explore neighbors recursively:</strong> Choose an adjacent node not yet visited and explore it recursively.</li>
                    <li><strong>Backtrack if necessary:</strong> If all adjacent nodes have been visited or there are no more adjacent nodes, backtrack to the previous node.</li>
                    <li><strong>Repeat until all nodes are visited:</strong> Continue the process until all nodes have been visited or until the desired condition is met.</li>
                </ol>

                <h2>When to use DFS</h2>
                <p>
                    DFS is suitable for problems involving exploring or searching through all possible paths or configurations, especially when the structure is represented as a tree or graph. It's commonly used for tasks such as finding paths, determining connectivity, detecting cycles, and more.
                </p>

                <h2>Data structure</h2>
                <p>
                    Typically, a stack is used to implement DFS, whether it's done iteratively or recursively. The stack keeps track of the nodes to be visited or explored.
                </p>

                <h2>Common problems solved using DFS</h2>
                <ol>
                    <li><strong>Finding connected components:</strong> Identify groups of nodes that are connected to each other.</li>
                    <li><strong>Cycle detection:</strong> Determine if a graph contains any cycles.</li>
                    <li><strong>Topological sorting:</strong> Arrange the nodes of a graph in a linear ordering that respects the dependencies between nodes.</li>
                </ol>

                <h2>Creating graph and implements Depth First Search (DFS) traversal on it (Python)</h2>
                <pre>
                    <code>
                        {`class Graph:
    def __init__(self):
        self.graph = {}

    def add_edge(self, u, v):
        if u not in self.graph:
            self.graph[u] = []
        self.graph[u].append(v)

    def dfs_util(self, node, visited):
        visited.add(node)
        print(node, end=' ')

        for neighbor in self.graph.get(node, []):
            if neighbor not in visited:
                self.dfs_util(neighbor, visited)

    def dfs(self, start):
        visited = set()
        self.dfs_util(start, visited)

# Example usage:
g = Graph()
g.add_edge(0, 1)
g.add_edge(0, 2)
g.add_edge(1, 2)
g.add_edge(2, 0)
g.add_edge(2, 3)
g.add_edge(3, 3)

print("DFS traversal starting from node 2:")
g.dfs(2)`}
                    </code>
                </pre>
                <p>Explanation:
                The add_edge method adds an edge from node u to node v in the graph. It first checks if node u exists in the graph. If not, it adds u as a key in the graph dictionary with an empty list as its value. Then, it appends v to the list of neighbors of u.

The dfs_util method is a utility function for performing DFS traversal recursively. It takes a node and a set of visited nodes as arguments. It marks the current node as visited, prints it, and then recursively calls itself for each unvisited neighbor of the current node.

The dfs method is the main DFS traversal function. It takes a starting node as an argument. It initializes an empty set to keep track of visited nodes and then calls the dfs_util method with the starting node and the set of visited nodes.

Example usage demonstrates how to create a graph using the Graph class and perform DFS traversal starting from a specific node. In this example, edges are added to create a simple directed graph, and then DFS traversal starting from node 2 is performed.</p>
                <p>
                    Time complexity: O(V + E) where V is the number of vertices and E is the number of edges. </p>
                    <p>Space complexity: O(V) for storing the visited set and the call stack in the worst case.
                </p>
            </div>
        );
    }


export default DFSExplanation;
