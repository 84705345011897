import React from 'react';
import './../contents.css';

const SlidingWindowExplanation : React.FC = () => {
    return (
        <div className="container">
            <h2>Sliding Window Algorithm</h2>
            <p>The sliding window algorithm is a technique used for solving problems involving arrays or strings by breaking them into smaller subarrays or substrings and then moving a "window" through them to find a solution efficiently. This approach is particularly useful for solving problems involving searching for a substring, subarray, or a specific pattern within a larger array or string.</p>
            
            <h2>Steps in Using Sliding Window</h2>
            <ol>
                <li><strong>Initialization:</strong> Initialize two pointers, usually at the beginning of the array or string, to define the starting position of the window.</li>
                <li><strong>Expansion:</strong> Expand the window by moving the right pointer to the right, increasing the size of the window.</li>
                <li><strong>Solution Update:</strong> Update the solution if necessary while the window meets the criteria of the problem.</li>
                <li><strong>Contraction:</strong> If the current window no longer satisfies the problem constraints, shrink the window by moving the left pointer to the right, reducing the size of the window.</li>
                <li><strong>Repeat:</strong> Repeat steps 2-4 until the right pointer reaches the end of the array or string.</li>
            </ol>
            
            <h2>When to Use Sliding Window</h2>
            <ul>
                <li>When dealing with problems involving substring or subarray.</li>
                <li>When the problem can be solved efficiently using a window that moves through the array or string.</li>
            </ul>
            
            <h2>Data Structure for Sliding Window</h2>
            <p>The data structure used typically depends on the problem requirements. Commonly used data structures include arrays, strings, or hash tables to store frequencies or counts of elements within the window.</p>
            
            <h2>Common Problems Solved Using Sliding Window Technique</h2>
            <ol>
                <li><strong>Maximum Sum Subarray of Size K:</strong> Find the maximum sum of a subarray of size K.</li>
                <li><strong>Longest Substring Without Repeating Characters:</strong> Find the length of the longest substring without repeating characters.</li>
                <li><strong>Smallest Subarray with a Sum Greater Than or Equal to a Given Value:</strong> Find the smallest subarray with a sum greater than or equal to a given value.</li>
                <li><strong>Fruit Into Baskets:</strong> Given an array of integers representing the number of fruits in each basket, find the maximum number of fruits you can collect with two baskets.</li>
            </ol>
            
            <h2>Maximum sum of subarray solved using sliding window (python)</h2>
            <pre>
                <code>
                    {`def max_sum_subarray(arr, k):
    window_sum = 0
    max_sum = float('-inf')
    left = 0
    
    for right in range(len(arr)):
        window_sum += arr[right]
        
        if right >= k - 1:
            max_sum = max(max_sum, window_sum)
            window_sum -= arr[left]
            left += 1
            
    return max_sum

# Example Usage
arr = [1, 3, -1, -3, 5, 3, 6, 7]
k = 3
print("Maximum sum of subarray of size", k, "is:", max_sum_subarray(arr, k))`}
                </code>
            </pre>
            <p>In this example, the function max_sum_subarray finds the maximum sum of a subarray of size k using the sliding window technique. The function iterates through the array, maintaining a window of size k. It updates the max_sum variable whenever the window size reaches k. It slides the window by incrementing the left pointer and decrementing the window sum accordingly. Finally, it returns the maximum sum found.</p>
            <p>Time Complexity: O(N), where N is the size of the array or string.<br />
            Space Complexity: O(1), as we are not using any extra space proportional to the input size.</p>
        </div>
    );
}

export default SlidingWindowExplanation;
