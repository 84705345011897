import React, { useState, useEffect } from 'react';

const SubmissionsTable: React.FC = () => {
  const [submissions, setSubmissions] = useState<any[]>([]);

  useEffect(() => {
    fetch('/api/get-contact-submissions')
      .then(response => response.json())
      .then(data => setSubmissions(data))
      .catch(error => console.error('Error fetching submissions:', error));
  }, []);

  const handleDelete = (index: number) => {
    // Make a request to delete the submission with the corresponding index
    fetch(`/api/delete-contact-submissions/${index}`, {
      method: 'DELETE',
    })
      .then(response => {
        if (response.ok) {
          // Remove the deleted submission from the state
          setSubmissions(prevSubmissions => prevSubmissions.filter((_, i) => i !== index));
        } else {
          throw new Error('Failed to delete submission');
        }
      })
      .catch(error => console.error('Error deleting submission:', error));
  };

  return (
    <div className='contact-submission-table'>
      <h2>Submissions</h2>
      <table>
        <thead>
          <tr>
            <th>Email</th>
            <th>Subject</th>
            <th>Query</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {submissions.map((submission, index) => (
            <tr key={index}>
              <td>{submission.email}</td>
              <td>{submission.subject}</td>
              <td>{submission.query}</td>
              <td><button onClick={() => handleDelete(index)}>Delete</button></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SubmissionsTable;
