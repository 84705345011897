import React from 'react';
import './../contents.css';

const DjikstraExplanation : React.FC = () => {
    return (
        <div className="container">
            <h2>Dijkstra's Algorithm</h2>
            <p>
                Dijkstra's algorithm is a widely used algorithm for finding the shortest paths between nodes in a graph, which may represent, for example, road networks or computer networks. It was conceived by Dutch computer scientist Edsger W. Dijkstra in 1956 and published three years later. The algorithm is used in various applications where finding the shortest path is essential, such as routing packets in networks, GPS systems, and more.
            </p>
            <h2>Explanation of Dijkstra's Algorithm</h2>
            <p>
                Dijkstra's algorithm works by iteratively exploring nodes in the graph starting from the source node and gradually building up the shortest paths to all other nodes. It maintains a set of tentative distances to each node, initially setting all distances to infinity except for the source node, which is set to 0. It then iterates, selecting the node with the smallest tentative distance, updating the distances to its neighbors, and marking it as visited. This process continues until all nodes have been visited or until the target node is reached.
            </p>
            <h2>Steps in using Dijkstra's Algorithm</h2>
            <ol>
                <li>Initialize distances: Set the distance of the source node to 0 and all other nodes to infinity.</li>
                <li>Create a priority queue or min-heap to store nodes with their tentative distances.</li>
                <li>While the priority queue is not empty:
                    <ul>
                        <li>Extract the node with the smallest tentative distance.</li>
                        <li>For each neighbor of the current node:
                            <ul>
                                <li>Calculate the distance from the source to the neighbor through the current node.</li>
                                <li>If this distance is shorter than the tentative distance recorded for the neighbor, update the tentative distance.</li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>Mark the current node as visited.</li>
                <li>Repeat steps 3-4 until all nodes have been visited or until the target node is reached.</li>
            </ol>
            <h2>When to use Dijkstra's Algorithm</h2>
            <p>
                Dijkstra's algorithm is used when the graph is weighted and directed, and you need to find the shortest path from a single source to all other nodes in the graph. It's not suitable for graphs with negative edge weights or cycles, as it assumes non-negative weights and will fail to find the correct shortest paths in such cases.
            </p>
            <h2>Data Structure to Use with Dijkstra's Algorithm</h2>
            <p>
                The main data structure used in Dijkstra's algorithm is a priority queue or min-heap to store nodes with their tentative distances. This allows for efficient selection of the node with the smallest tentative distance at each step.
            </p>
            <h2>Common Problems Solved Using Dijkstra's Algorithm</h2>
            <ul>
                <li>Finding the shortest path in road networks or maps.</li>
                <li>Routing packets in computer networks.</li>
                <li>Finding the shortest path in transportation networks.</li>
                <li>Optimizing travel routes in logistics.</li>
            </ul>
            <h2>Python Code Example</h2>
            <pre>
                <code>
{`import heapq

def dijkstra(graph, source):
    distances = {node: float('inf') for node in graph}
    distances[source] = 0
    priority_queue = [(0, source)]

    while priority_queue:
        current_distance, current_node = heapq.heappop(priority_queue)

        if current_distance > distances[current_node]:
            continue

        for neighbor, weight in graph[current_node].items():
            distance = current_distance + weight
            if distance < distances[neighbor]:
                distances[neighbor] = distance
                heapq.heappush(priority_queue, (distance, neighbor))

    return distances

# Example usage:
graph = {
    'A': {'B': 3, 'C': 1},
    'B': {'A': 3, 'C': 7, 'D': 5},
    'C': {'A': 1, 'B': 7, 'D': 2},
    'D': {'B': 5, 'C': 2}
}
source_node = 'A'
shortest_distances = dijkstra(graph, source_node)
print("Shortest distances from", source_node, ":", shortest_distances)`}
                </code>
            </pre>
            <p>
                This Python code defines a function dijkstra that takes a graph and a source node as input and returns a dictionary containing the shortest distances from the source node to all other nodes in the graph. It uses a priority queue (priority_queue) to store nodes along with their tentative distances. The heapq module from the Python standard library is used to implement the priority queue.
            </p>

            <p>
                Time and Space Complexity:
                The time complexity of Dijkstra's algorithm using a priority queue is O((V + E) log V), where V is the number of vertices and E is the number of edges in the graph. The space complexity is O(V) for storing the distances and the priority queue.
            </p>
        </div>
    );
}

export default DjikstraExplanation;
