interface MenuItem {
    label: string;
    link: string;
  }
  
  interface SubMenu {
    [key: string]: MenuItem[];
  }
  
  export const subMenuItems: SubMenu = {
    data_structures: [
      { label: 'Array', link: '#' },
      { label: 'Maps', link: '#' },
      { label: 'Tuples', link: '#' },
      { label: 'Sets', link: '#' },
      { label: 'LinkedList', link: '#' },
      { label: 'Stacks', link: '#' },
      { label: 'Queue', link: '#' },
      { label: 'Trees', link: '#' },
      { label: 'Graphs', link: '#' },
      { label: 'Tries', link: '#' },
      { label: 'Heaps', link: '#' },
    ],
    algorithm: [
      { label: 'Two Pointer', link: '#' },
      { label: 'Sliding Window', link: '#' },
      { label: 'Binary Search', link: '#' },
      { label: 'Backtracking', link: '#' },
      { label: 'Dynamic Programming', link: '#' },
      { label: 'Greedy', link: '#' },
      { label: 'Breath-first Search', link: '#' },
      { label: 'Depth-first Search', link: '#' },
      { label: "Dijkstra's", link: '#' },
      { label: "Prim's", link: '#' },
      { label: "Topological Sorting", link: '#' },

    ]
    // Add more sub-menus as needed
  };
  