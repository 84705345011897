import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Blogs from './pages/Blogs';
import AboutPage from './pages/About';
import ContactForm from './pages/Contact';
import SubmissionsTable from './pages/ContactSubmissions';

import './App.css';

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        {/* Define routes */}
        <Routes>
          <Route path="/" element={<HomePage/>} /> {/* Home route */}
          <Route path="/blogs/:data_structure/:dataType" element={<Blogs/>} /> {/* Route for DataStructurePage */}
          <Route path="/about" element={<AboutPage/>} /> 
          <Route path="/contact" element={<ContactForm/>} /> 
          <Route path="/contact-submissions" element={<SubmissionsTable/>} /> 
        </Routes>
      </div>
    </Router>
  );
};

export default App;
