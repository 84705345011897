import React from 'react';
import './../contents.css';

const ArraysExplanation: React.FC = () => {
  return (
    <div className="container">
      <h2 className="subheading">Arrays in Programming</h2>
      <p>Arrays are fundamental data structures in programming, used to store collections of elements of the same data type. They provide a way to organize and access data in a contiguous block of memory.</p>
      
      <h2 className="subheading">Characteristics of Arrays</h2>
      <ol>
        <li><strong>Homogeneity:</strong> Arrays hold elements of the same data type.</li>
        <li><strong>Contiguous Memory:</strong> Array elements are stored in adjacent memory locations.</li>
        <li><strong>Fixed Size:</strong> Arrays have a fixed size that is determined when they are declared.</li>
      </ol>

      <h2 className="subheading">Declaration and Initialization</h2>
      <pre><code className="language-python">my_array = [1, 2, 3, 4, 5] # example in python</code></pre>

      <h2 className="subheading">Accessing Elements</h2>
      <pre><code className="language-python">print(my_array[0])  # Output: 1
print(my_array[2])  # Output: 3</code></pre>

      <h2 className="subheading">Common Operations</h2>
      <ul>
        <li><strong>Insertion:</strong> Elements can be inserted into an array at a specific index.</li>
        <li><strong>Deletion:</strong> Elements can be removed from an array.</li>
        <li><strong>Traversal:</strong> Looping through an array to access each element for processing.</li>
        <li><strong>Searching:</strong> Finding the position of a specific element within the array.</li>
        <li><strong>Sorting:</strong> Arranging the elements of the array in a specified order.</li>
      </ul>

      <h2 className="subheading">Advantages of Arrays</h2>
      <ul>
        <li><strong>Random Access:</strong> Elements can be accessed directly using their indices.</li>
        <li><strong>Compact Memory Representation:</strong> Arrays store elements in contiguous memory blocks.</li>
      </ul>

      <h2 className="subheading">Disadvantages of Arrays</h2>
      <ul>
        <li><strong>Fixed Size:</strong> Arrays have a fixed size, making it challenging to resize them dynamically.</li>
        <li><strong>Inefficient Insertion and Deletion:</strong> Inserting or deleting elements in the middle of an array can be inefficient.</li>
        <li><strong>Homogeneity Requirement:</strong> All elements in an array must be of the same data type.</li>
      </ul>

      <h2 className="subheading">When to Use the Array Data Structure in Coding Problems</h2>
      <p>The array data structure is widely used in coding problems and algorithmic challenges. Knowing when to use arrays can help you write efficient and concise solutions. Consider using arrays in the following scenarios:</p>
      <ul>
        <li><strong>Sequential Data:</strong> Arrays are suitable for storing sequential data, such as lists of numbers, characters, or objects. If the problem involves processing a sequence of elements in a specific order, arrays provide a straightforward solution.</li>
        <li><strong>Index-Based Operations:</strong> When the problem requires frequent access to elements by their index or position in the collection, arrays offer constant-time random access. This property is useful for tasks like searching, sorting, or modifying elements based on their positions.</li>
        <li><strong>Space Efficiency:</strong> In problems with space constraints or requirements for efficient memory usage, arrays are preferable due to their compact representation. They allocate a contiguous block of memory, minimizing overhead and reducing memory fragmentation.</li>
        <li><strong>Performance Considerations:</strong> Arrays are often the most efficient data structure for basic operations like insertion, deletion, and traversal. If the problem involves simple data manipulation tasks that can benefit from direct memory access and minimal overhead, arrays are a good choice.</li>
      </ul>


      <h2 className="subheading">Arrays in Different Programming Languages</h2>
      <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Language</th>
            <th>Syntax</th>
            <th>Dynamic Sizing</th>
            <th>Common Operations</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Python</td>
            <td>my_array = [1, 2, 3, 4, 5]</td>
            <td>Yes</td>
            <td>Indexing, slicing, appending, extending, removing</td>
          </tr>
          <tr>
            <td>JavaScript</td>
            <td>let myArray = [1, 2, 3, 4, 5];</td>
            <td>Yes</td>
            <td>Indexing, slicing, push, pop, shift, unshift, splice</td>
          </tr>
          <tr>
            <td>C++</td>
            <td>{`int myArray[] = {1, 2, 3, 4, 5};`}</td>
            <td>No</td>
            <td>Indexing, iterating, accessing elements</td>
          </tr>
          <tr>
            <td>Java</td>
            <td>{`int[] myArray = {1, 2, 3, 4, 5};`}</td>
            <td>Yes</td>
            <td>Indexing, looping, appending, removing</td>
          </tr>
          <tr>
            <td>C#</td>
            <td>{`int[] myArray = {1, 2, 3, 4, 5};`}</td>
            <td>Yes</td>
            <td>Indexing, looping, appending, removing</td>
          </tr>
          {/* Add more rows for other languages */}
        </tbody>
      </table>
      </div>

      <p>Arrays in different programming languages may have varying syntax and capabilities. While some languages provide dynamic sizing and built-in methods for common operations, others may require manual memory management and have limited functionality.</p>
    </div>
  );
}

export default ArraysExplanation;
