import React from 'react';
import './../contents.css';

const DynamicProgrammingExplanation : React.FC = () => {
    return (
        <div className="container">
            <h2>Dynamic Programming Overview</h2>
            <p>
                Dynamic programming is a method for solving complex problems by breaking them down into simpler subproblems
                and storing the solutions to those subproblems to avoid redundant computations. It's typically used for
                optimization problems where the solution can be obtained by combining solutions to smaller subproblems.
                Dynamic programming can significantly improve the efficiency of algorithms by caching intermediate results.
            </p>
            <h2>Steps in Using Dynamic Programming</h2>
            <ol>
                <li> <strong>Identify optimal substructure: </strong>Determine if the problem can be broken down into smaller subproblems, and if the optimal solution to the problem can be constructed from the optimal solutions of its subproblems.</li>
                <li><strong>Define the state: </strong>Identify the parameters that uniquely define a subproblem. These parameters will be used to index the memoization table or dynamic programming array.</li>
                <li><strong>Formulate a recurrence relation: </strong> Express the solution to a subproblem in terms of the solutions to its smaller subproblems.</li>
                <li><strong>Design an algorithm: </strong>Decide whether to use top-down (memoization) or bottom-up (tabulation) approach to solve the problem.</li>
                <li><strong>Implement the algorithm: </strong>Write the code to solve the problem based on the chosen approach.</li>

            </ol>
            <h2>When to Use Dynamic Programming:</h2>
            <p>
                Dynamic programming is suitable for problems with overlapping subproblems and optimal substructure.
                Some indicators that dynamic programming may be applicable include:
                <ul>
                    <li>The problem can be divided into smaller subproblems.</li>
                    <li>The same subproblem is solved multiple times.</li>
                    <li>The problem exhibits optimal substructure.</li>
                </ul>
            </p>
            <h2>Types of Dynamic Programming</h2>
            <ul>
                <li>Memoization: Top-down approach where solutions to subproblems are stored and reused.</li>
                <li>Tabulation: Bottom-up approach where solutions to subproblems are iteratively computed and stored
                    in a table.</li>
            </ul>
            <h2>Data Structures for Dynamic Programming:</h2>
            <p>
                Dynamic programming algorithms often utilize arrays, matrices, or hash tables to store solutions to
                subproblems efficiently.
            </p>
            <h2>Common Problems Solved Using Dynamic Programming</h2>
            <ul>
                <li>Fibonacci sequence</li>
                <li>Shortest path problems</li>
                <li>Longest common subsequence</li>
                <li>Knapsack problem</li>
            </ul>
            <h2>Example in Python (Fibonacci)</h2>
            <pre>
                <code>
{`def fibonacci(n):
    if n <= 1:
        return n
    fib = [0] * (n + 1)
    fib[1] = 1
    for i in range(2, n + 1):
        fib[i] = fib[i - 1] + fib[i - 2]
    return fib[n]

# Example usage
print(fibonacci(10))  # Output: 55`}
                </code>
            </pre>
            <p>Explanation:</p>
            <ul>
                <li>The function fibonacci(n) computes the nth Fibonacci number.</li>
                <li>It initializes an array "fib" of size "n+1" to store the Fibonacci numbers.</li>
                <li>It iteratively fills the array with Fibonacci numbers from the bottom up.</li>
                <li>Finally, it returns the nth Fibonacci number.</li>
                <li>Time complexity: O(n)</li>
                <li>Space complexity: O(n)</li>
            </ul>
        </div>
    );
}

export default DynamicProgrammingExplanation;
