import React from 'react';
import './../contents.css';

const GreedyExplanation : React.FC = () => {
    return (
        <div className="container">
            <h2>Greedy Algorithm Explanation</h2>
                <p>A greedy algorithm is a simple, intuitive algorithmic paradigm that builds up a solution piece by piece, always choosing the next piece that offers the most immediate benefit. The key characteristic of greedy algorithms is that they make a series of choices, each choice being locally optimal with the hope that these choices will lead to a globally optimal solution.</p>
            <h2>Steps in Using Greedy Algorithm</h2>
            <ol>
                <li><strong>Identify the problem:</strong> Recognize that the problem can be solved using a greedy approach. Greedy algorithms are most effective when the problem has optimal substructure and the greedy choice property.</li>
                <li><strong>Define the objective function:</strong> Determine the criteria for making each choice.</li>
                <li><strong>Identify the greedy choice:</strong> At each step, choose the best option according to the objective function.</li>
                <li><strong>Solve subproblems:</strong> After making the greedy choice, solve the smaller subproblems that arise.</li>
                <li><strong>Combine solutions:</strong> Finally, combine the solutions of the subproblems to form the solution to the original problem.</li>
            </ol>

            <h2>When to Use Greedy</h2>
            <p>
                Greedy algorithms are suitable for optimization problems where a locally optimal choice can lead to a globally optimal solution.
                They are used when the problem exhibits the properties of optimal substructure and greedy choice.
            </p>

            <h2>Data Structures for Greedy Algorithms</h2>
            <p>
                Common data structures used in greedy algorithms include arrays, lists, priority queues (heap), and dictionaries/maps.
            </p>

            <h2>Common Problems Solved Using Greedy Algorithm</h2>
            <ul>
                <li>Minimum Spanning Tree</li>
                <li>Shortest Path</li>
                <li>Activity Selection</li>
                <li>Fractional Knapsack</li>
            </ul>

            <h2>Example: Fractional Knapsack Problem</h2>
            <pre>
                <code>
{`function fractional_knapsack(items, capacity) {
    items.sort(key=lambda x: x[1]/x[0], reverse=True);
    total_value = 0
    for weight, value in items:
        if capacity >= weight:
            total_value += value
            capacity -= weight
        else:
            total_value += (capacity / weight) * value
            break
    return total_value
}

// Example usage
items = [(10, 60), (20, 100), (30, 120)]  # (weight, value) pairs
capacity = 50
console.log("Maximum value obtained:", fractional_knapsack(items, capacity))`}
                </code>
            </pre>
            <p>
                Explanation:
                <ul>
                    <li>The function `fractional_knapsack` takes a list of items and the capacity of the knapsack as input.</li>
                    <li>It sorts the items based on their value-to-weight ratio in non-increasing order.</li>
                    <li>It then iterates through the sorted items, adding them to the knapsack as much as possible.</li>
                    <li>If adding the whole item exceeds the capacity, it adds a fraction of the item to maximize the total value.</li>
                    <li>Finally, it returns the maximum total value obtainable.</li>
                </ul>
                Time Complexity: O(n log n) due to sorting, where n is the number of items.<br/>
                Space Complexity: O(1) as it only uses a constant amount of extra space.
            </p>
        </div>
    );
}

export default GreedyExplanation;
