import React from 'react';
import './../contents.css';

const TrieExplanation: React.FC = () => {
    return (
        <div className="container">
            <h2>Tries in Programming</h2>
            <p>
                A trie, also known as a prefix tree, is a tree-like data structure used to efficiently store and retrieve strings or keys associated with values. Each node in a trie represents a common prefix shared by a set of strings.
            </p>
            <h2>Characteristics of Tries</h2>
            <ul>
                <li><strong>Prefix-based:</strong> Trie nodes represent prefixes of strings.</li>
                <li><strong>Memory Efficiency:</strong> Tries excel in scenarios where keys share common prefixes.</li>
                <li><strong>Fast Lookup:</strong> Retrieving a value has a time complexity of O(m), where m is the key length.</li>
                <li><strong>Dynamic Insertion and Deletion:</strong> Tries support efficient dynamic operations.</li>
                <li><strong>Ordered Iteration:</strong> Depending on the implementation, tries can support ordered iteration.</li>
            </ul>
            <h2>Advantages of Tries</h2>
            <ul>
                <li><strong>Efficient Prefix Searches:</strong> Ideal for autocomplete and dictionary implementations.</li>
                <li><strong>Dynamic Operations:</strong> Efficiently support dynamic insertion and deletion.</li>
                <li><strong>Space Optimization:</strong> Save space by sharing common prefixes.</li>
                <li><strong>Ordered Iteration:</strong> Facilitate ordered iteration of keys.</li>
            </ul>
            <h2>Disadvantages of Tries</h2>
            <ul>
                <li><strong>Memory Consumption:</strong> Can be memory-intensive for large datasets.</li>
                <li><strong>Complexity:</strong> Implementing and maintaining tries can be complex.</li>
                <li><strong>Overhead:</strong> May have overhead compared to simpler data structures.</li>
            </ul>
            <h2>When to Use Tries</h2>
            <ul>
                <li>Use for prefix-based searching like autocomplete.</li>
                <li>Use when dataset contains keys with shared prefixes to optimize space.</li>
                <li>Use for dynamic datasets requiring frequent insertions and deletions.</li>
            </ul>
            <h2>Time and Space Complexity of Trie Operations</h2>
            <div className="table-container">
            <table>
                <thead>
                    <tr>
                        <th>Operation</th>
                        <th>Time Complexity</th>
                        <th>Space Complexity</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Insertion</td>
                        <td>O(m)</td>
                        <td>O(m)</td>
                    </tr>
                    <tr>
                        <td>Deletion</td>
                        <td>O(m)</td>
                        <td>O(1)</td>
                    </tr>
                    <tr>
                        <td>Search</td>
                        <td>O(m)</td>
                        <td>O(1)</td>
                    </tr>
                    <tr>
                        <td>Prefix Matching</td>
                        <td>O(k)</td>
                        <td>O(1)</td>
                    </tr>
                </tbody>
            </table>
            </div>
            <h2>Code Example of Trie in Python</h2>
            <pre>
                <code>{`
class TrieNode:
    def __init__(self):
        self.children = {}
        self.is_end_of_word = False

class Trie:
    def __init__(self):
        self.root = TrieNode()
    
    def insert(self, word):
        node = self.root
        for char in word:
            if char not in node.children:
                node.children[char] = TrieNode()
            node = node.children[char]
        node.is_end_of_word = True
    
    def search(self, word):
        node = self.root
        for char in word:
            if char not in node.children:
                return False
            node = node.children[char]
        return node.is_end_of_word
    
    def startsWith(self, prefix):
        node = self.root
        for char in prefix:
            if char not in node.children:
                return False
            node = node.children[char]
        return True

# Example usage:
trie = Trie()
trie.insert("apple")
print(trie.search("apple"))  # Output: True
print(trie.search("app"))    # Output: False
print(trie.startsWith("app")) # Output: True
                `}
                </code>
            </pre>
        </div>
    );
}

export default TrieExplanation;
