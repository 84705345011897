import React from 'react';
import './../contents.css';

const BinarySearchExplanation : React.FC = () => {
    return (
        <div className="container">
                <h2>Binary Search Algorithm</h2>
                <p>
                    Binary search is a highly efficient algorithm used to search for an element in a sorted array or list. It works by repeatedly dividing in half the portion of the list that could contain the target value until it finds the value or determines that it is not present.
                </p>
                <h2>Steps in using binary search</h2>                <ol>
                    <li><strong>Initialize:</strong> Start with the entire sorted array.</li>
                    <li><strong>Compare:</strong> Compare the target value with the middle element of the array.</li>
                    <li><strong>Divide and Conquer:</strong> If the target value matches the middle element, the search is successful. If the target value is less than the middle element, continue the search on the lower half of the array. If the target value is greater, continue the search on the upper half.</li>
                    <li><strong>Repeat:</strong> Repeat the process until the target value is found or the search space is empty.</li>
                </ol>
                <h2>When to use binary search:</h2>
                <p>
                    Binary search is effective when:
                    <ul>
                        <li>The data is sorted.</li>
                        <li>The dataset is large.</li>
                        <li>Random access to elements is allowed (e.g., arrays).</li>
                        <li>There is a need for efficient searching.</li>
                    </ul>
                </p>
                <h2>Data structure for binary search</h2>
                <p>
                    Binary search is commonly implemented using arrays or lists, as it requires random access to elements. Arrays are preferred due to their contiguous memory allocation and faster access times.
                </p>
                <h2>Common Problems Solved Using Binary Search</h2>
                <ul>
                    <li><strong>Searching:</strong> Finding an element in a sorted array.</li>
                    <li><strong>Finding Bounds:</strong> Determining the position of an element or the nearest smaller/larger element.</li>
                    <li><strong>Checking monotonicity:</strong> Determining whether a function is increasing/decreasing over a given range.</li>
                </ul>
                <h2>Binary Search Python Code Example</h2>
                <pre>
                    <code>
                        {`
def binary_search(arr, target):
    low = 0
    high = len(arr) - 1
    
    while low <= high:
        mid = (low + high) // 2
        if arr[mid] == target:
            return mid
        elif arr[mid] < target:
            low = mid + 1
        else:
            high = mid - 1
    
    return -1

# Example usage:
arr = [1, 3, 5, 7, 9, 11, 13]
target = 9
print("Index of target:", binary_search(arr, target))
                        `}
                    </code>
                </pre>

                <p>
                    Explanation: The function "binary_search" takes a sorted array "arr" and a target value "target". It initializes two pointers, "low" and "high", representing the range of elements to search. It repeatedly calculates the middle index (mid) and compares the target value with the middle element. Based on the comparison, it updates the range of search (either narrowing it down to the lower half or the upper half). If the target is found, it returns the index of the target; otherwise, it returns -1 if the target is not present in the array. In the example usage, it searches for the index of "9" in the array [1, 3, 5, 7, 9, 11, 13] and prints the result.
                </p>

                <ul>
                    <li><strong>Time Complexity:</strong> O(log n) - Binary search reduces the search space by half with each iteration, leading to a logarithmic time complexity.</li>
                    <li><strong>Space Complexity:</strong> O(1) - Binary search requires a constant amount of extra space for storing variables, irrespective of the input size.</li>
                </ul>
            </div>
        );
    }

export default BinarySearchExplanation;
