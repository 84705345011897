import React, { useEffect, useState } from 'react';

const LoadingIndicator: React.FC = () => {
  const loadingTexts = ['Analyzing Text...', 'Pre-processing...', 'Creating Solution...'];
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    // Change loading text every 2 seconds
    const intervalId = setInterval(() => {
      setCurrentTextIndex((prevIndex) => {
        // Check if the current index is the last index
        if (prevIndex === loadingTexts.length - 1) {
          // If so, keep showing the last text ('Almost there...')
          return prevIndex;
        } else {
          // Otherwise, move to the next index
          return prevIndex + 1;
        }
      });
    }, 3000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [loadingTexts.length]);

  return (
    <div className="loading">
      {/* Loading indicator */}
      <div className="spinner"></div>
      <div className='spinner-text'>
        <p className='loading-text'>{loadingTexts[currentTextIndex]}</p>
      </div>
    </div>
  );
};

export default LoadingIndicator;
