import React from 'react';
import './../contents.css';


const HeapsExplanation: React.FC = () => {
  return (
    <div className="container">
      <h2>Heaps in Programming</h2>
      <p>Heaps in programming are a type of binary tree-based data structure that satisfy the heap property. This property ensures that each node in the heap contains a value greater than or equal to (in a max heap) or less than or equal to (in a min heap) the values of its children.</p>

      <h2>Characteristics of Heaps</h2>
      <ul>
        <li><strong>Complete Binary Tree:</strong> Heaps are implemented as complete binary trees...</li>
        <li><strong>Heap Property:</strong> The value of each node is either greater than or equal to (max heap) or less than or equal to (min heap) the values of its children...</li>
        <li><strong>Efficient Operations:</strong> Heaps support efficient insertion, deletion, and retrieval of the maximum (or minimum) element...</li>
        <li><strong>Priority Queue:</strong> Heaps are often used to implement priority queues...</li>
        <li><strong>Not Suitable for Searching:</strong> While heaps support efficient operations for insertion and deletion of the maximum (or minimum) element...</li>
      </ul>

      <h2>Advantages of Heaps</h2>
      <ul>
        <li><strong>Efficient Operations:</strong> Heaps offer efficient insertion and removal of the maximum (or minimum) element in O(log n) time complexity...</li>
        <li><strong>Priority Queue Implementation:</strong> They provide an efficient way to implement priority queues...</li>
        <li><strong>Space Efficiency:</strong> Heaps are space-efficient compared to other data structures like arrays when dealing with dynamic sets...</li>
      </ul>

      <h2>Disadvantages of Heaps</h2>
      <ul>
        <li><strong>Limited Functionality:</strong> Heaps are designed for priority queue operations...</li>
        <li><strong>Not Stable:</strong> The heap structure does not maintain the relative order of elements with equal keys...</li>
        <li><strong>Complexity in Implementation:</strong> While the basic operations on heaps are straightforward...</li>
      </ul>

      <h2>When to Use Tries</h2>
      <p>Tries are data structures used for storing strings or associative arrays where the keys are usually strings...</p>

      <h2>Time and Space Complexity of Heap Operations</h2>
      <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Operation</th>
            <th>Time Complexity (worst-case)</th>
            <th>Space Complexity</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Insertion</td>
            <td>O(log n)</td>
            <td>O(1)</td>
          </tr>
          <tr>
            <td>Deletion</td>
            <td>O(log n)</td>
            <td>O(1)</td>
          </tr>
          <tr>
            <td>Find Max/Min</td>
            <td>O(1)</td>
            <td>O(1)</td>
          </tr>
          <tr>
            <td>Heapify</td>
            <td>O(n)</td>
            <td>O(1)</td>
          </tr>
          <tr>
            <td>Build Heap</td>
            <td>O(n)</td>
            <td>O(1)</td>
          </tr>
        </tbody>
      </table>
      </div>
      <h2>Code Example of Heaps in Python</h2>
      <pre>
        <code>
          {`import heapq

# Creating a min heap
heap = []
heapq.heappush(heap, 10)
heapq.heappush(heap, 5)
heapq.heappush(heap, 15)
print(heap)  # Output: [5, 10, 15]

# Popping the smallest element
print(heapq.heappop(heap))  # Output: 5
print(heap)  # Output: [10, 15]`}
        </code>
      </pre>

      <h2>Differences in Heaps Across Programming Languages</h2>
      <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Feature</th>
            <th>Python (using heapq)</th>
            <th>Java (using PriorityQueue)</th>
            <th>C++ (using priority_queue)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Type</td>
            <td>Min Heap</td>
            <td>Min Heap</td>
            <td>Max Heap</td>
          </tr>
          <tr>
            <td>Default Implementation</td>
            <td>Yes</td>
            <td>No</td>
            <td>Yes (max heap)</td>
          </tr>
          <tr>
            <td>Custom Comparator</td>
            <td>No</td>
            <td>Yes</td>
            <td>Yes</td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default HeapsExplanation;
