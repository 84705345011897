import React from 'react';
import './../contents.css';

const PrimsExplanation : React.FC = () => {
    return (
        <div className="container">
      <h2>Prim's Algorithm</h2>
      <p>Prim's algorithm is a greedy algorithm used to find the minimum spanning tree (MST) of a connected, undirected graph. The minimum spanning tree of a graph is a subset of the edges that forms a tree connecting all the vertices together with the minimum possible total edge weight.</p>
      
      <h2>Steps in Prim's Algorithm</h2>
      <ol>
        <li><strong>Start with an arbitrary vertex:</strong> Choose any vertex from the graph to start the minimum spanning tree.</li>
        <li><strong>Add the closest edge:</strong> Find the edge with the smallest weight that connects the chosen vertex to any other vertex not yet in the minimum spanning tree, and add it to the tree.</li>
        <li><strong>Update the set of candidate edges:</strong> Update the set of candidate edges by considering all edges that connect vertices in the minimum spanning tree to vertices not yet in the tree.</li>
        <li><strong>Repeat:</strong> Repeat steps 2 and 3 until all vertices are in the minimum spanning tree.</li>
      </ol>
      
      <h2>When to Use Prim's Algorithm</h2>
      <p>Prim's algorithm is suitable for finding the minimum spanning tree in undirected graphs where the edges have weights. It is particularly efficient when the graph is dense (i.e., there are many edges), as its time complexity is O(E log V), where V is the number of vertices and E is the number of edges.</p>
      
      <h2>Data Structure for Prim's Algorithm</h2>
      <p>A priority queue or a min-heap is commonly used to efficiently find the minimum weight edge in each iteration of the algorithm. This allows for quick retrieval of the edge with the smallest weight connecting the minimum spanning tree to the vertices not yet in the tree.</p>
      
      <h2>Common Problems Solved Using Prim's Algorithm</h2>
      <ul>
        <li>Finding the minimum cost to connect all nodes in a network.</li>
        <li>Designing efficient network layouts or routing algorithms.</li>
        <li>Optimizing circuit design in electrical engineering.</li>
        <li>Clustering in data analysis, such as hierarchical clustering.</li>
      </ul>
      
      <h2>Python Code Example</h2>
      <pre>
        <code>
          {`
import heapq

def prim(graph):
    # Initialize an empty minimum spanning tree and set of visited vertices
    mst = []
    visited = set()
    
    # Choose an arbitrary starting vertex and mark it as visited
    start_vertex = list(graph.keys())[0]
    visited.add(start_vertex)
    
    # Initialize the priority queue with edges connected to the starting vertex
    edges = [(weight, start_vertex, neighbor) for neighbor, weight in graph[start_vertex]]
    heapq.heapify(edges)
    
    # Iterate until all vertices are visited
    while edges:
        weight, u, v = heapq.heappop(edges)
        if v not in visited:
            # Add the edge to the minimum spanning tree and mark the vertex as visited
            mst.append((u, v, weight))
            visited.add(v)
            # Add new edges connected to the visited vertex to the priority queue
            for neighbor, weight in graph[v]:
                if neighbor not in visited:
                    heapq.heappush(edges, (weight, v, neighbor))
    
    return mst

# Example graph represented as an adjacency list
graph = {
    'A': [('B', 2), ('C', 3)],
    'B': [('A', 2), ('C', 4), ('D', 5)],
    'C': [('A', 3), ('B', 4), ('D', 1)],
    'D': [('B', 5), ('C', 1)]
}

# Run Prim's algorithm on the example graph
minimum_spanning_tree = prim(graph)
print("Minimum Spanning Tree:")
for edge in minimum_spanning_tree:
    print(edge)

          `}
        </code>
      </pre>
      
      <p>Explanation of the Code:

The prim function takes a graph represented as an adjacency list as input and returns the minimum spanning tree as a list of edges.
It initializes an empty minimum spanning tree (mst) and a set of visited vertices (visited).
It starts with an arbitrary vertex (start_vertex) and adds it to the set of visited vertices.
It initializes a priority queue (edges) with edges connected to the starting vertex.
It iterates until all vertices are visited, popping the edge with the smallest weight from the priority queue.
If the destination vertex of the edge is not visited, it adds the edge to the minimum spanning tree and marks the vertex as visited. It then adds new edges connected to the visited vertex to the priority queue.
Finally, it returns the minimum spanning tree.
The example graph is defined as an adjacency list, and Prim's algorithm is applied to find the minimum spanning tree.
The minimum spanning tree is printed out, showing the edges and their weights.</p>
      
      <p>Time and Space Complexity: The time complexity of Prim's algorithm is O(E log V), where V is the number of vertices and E is the number of edges. The space complexity is O(V + E).</p>
    </div>
  );
}

export default PrimsExplanation;
