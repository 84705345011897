// componentsData.ts

import React from 'react';
import TwoPointerExplanation from './explanations/TwoPointerExplanation';
import SlidingWindowExplanation from './explanations/SlidingWindowExplanation';
import BinarySearchExplanation from './explanations/BinarySearchAlgorithm';
import BacktrackingExplanation from './explanations/BackTracking';
import DynamicProgrammingExplanation from './explanations/DynamicProgramming';
import GreedyExplanation from './explanations/GreedyExplanation';
import BFSExplanation from './explanations/BreathFirstSearch';
import DFSExplanation from './explanations/DFSExplanation';
import DjikstraExplanation from './explanations/DjikstraExplanation';
import PrimsExplanation from './explanations/PrimsExplanation';
import TopologicalSortingExplanation from './explanations/TopologicalSortingExplanation';


export interface AlgoComponentData {
  title: string;
  description: string;
}

export const algorithms_components: Record<string, { component: React.FC, info: AlgoComponentData }> = {
  "Two Pointer": {
        component: TwoPointerExplanation,
        info: {
        title: 'What is Two Pointer Algorithm?',
        description: 'This explains Two Pointer in different programming language'
        }
    },
    "Sliding Window": {
        component: SlidingWindowExplanation,
        info: {
        title: 'What is Sliding Window Algorithm?',
        description: 'This explains Sliding Window in different programming language'
        }
    },
    "Binary Search": {
        component: BinarySearchExplanation,
        info: {
        title: 'What is Binary Search?',
        description: 'This explains Binary Search in different programming language'
        }
    },
    "Backtracking": {
        component: BacktrackingExplanation,
        info: {
        title: 'What is Backtracking Algorithm?',
        description: 'This explains Backtracking in different programming language'
        }
    },
    "Dynamic Programming": {
        component: DynamicProgrammingExplanation,
        info: {
        title: 'What is Dynamic Programming?',
        description: 'This explains Dynamic Programming in different programming language'
        }
    },
    "Greedy": {
        component: GreedyExplanation,
        info: {
        title: 'What is Greedy Algorithm?',
        description: 'This explains Greedy in different programming language'
        }
    },
    "Breath-first Search": {
        component: BFSExplanation,
        info: {
        title: 'What is Breath-first Search?',
        description: 'This explains Breath First Search in different programming language'
        }
    },
    "Depth-first Search": {
        component: DFSExplanation,
        info: {
        title: 'What is Depth-first Search?',
        description: 'This explains Depth First Search in different programming language'
        }
    },
    "Dijkstra's": {
        component: DjikstraExplanation,
        info: {
        title: "What is Dijkstra's Algorithm?",
        description: "This explains Dijkstra's in different programming language"
        }
    },
    "Prim's": {
        component: PrimsExplanation,
        info: {
        title: "What is Prim's Algorithm?",
        description: "This explains Prim's in different programming language"
        }
    },
    "Topological Sorting": {
        component: TopologicalSortingExplanation,
        info: {
        title: "What is Topological Sorting Algorithm?",
        description: "This explains Topological Sorting in different programming language"
        }
    },
};
