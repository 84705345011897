import React from 'react';
import './../contents.css';

const GraphsExplanation: React.FC = () => {
  return (
    <div className="container">
      <h2>Graphs in programming</h2>
      <p>
        Graphs in programming are a fundamental data structure used to represent relationships between pairs of objects. They consist of vertices (also called nodes) and edges, where edges represent connections between vertices. Graphs are widely used in various domains, including computer networking, social networks, recommendation systems, and routing algorithms.
      </p>
      <h2>Characteristics of Graphs</h2>
      <ul>
        <li>
          <strong>Vertices (Nodes):</strong> Represent entities or objects.
        </li>
        <li>
          <strong>Edges:</strong> Represent relationships or connections between vertices.
        </li>
        <li>
          <strong>Directed vs. Undirected:</strong> In a directed graph, edges have a direction, while in an undirected graph, edges have no direction.
        </li>
        <li>
          <strong>Weighted vs. Unweighted:</strong> Edges may have weights to represent the cost or distance between vertices.
        </li>
        <li>
          <strong>Cyclic vs. Acyclic:</strong> A graph is cyclic if there is a cycle (a path that starts and ends at the same vertex), otherwise, it is acyclic.
        </li>
        <li>
          <strong>Connectedness:</strong> A graph is connected if there is a path between every pair of vertices.
        </li>
        <li>
          <strong>Degree:</strong> The number of edges incident to a vertex.
        </li>
      </ul>
      <h2>Advantages of Graphs</h2>
      <ul>
        <li>
          <strong>Versatility:</strong> Graphs can model complex relationships in a wide range of applications.
        </li>
        <li>
          <strong>Efficient Representation:</strong> Allows for efficient representation and manipulation of relationships between entities.
        </li>
        <li>
          <strong>Flexibility:</strong> Supports various algorithms for traversing, searching, and analyzing data.
        </li>
        <li>
          <strong>Scalability:</strong> Can scale to represent large and complex networks efficiently.
        </li>
      </ul>
      <h2>Disadvantages of Graphs</h2>
      <ul>
        <li>
          <strong>Complexity:</strong> Implementing algorithms on graphs can be complex compared to other data structures.
        </li>
        <li>
          <strong>Space Consumption:</strong> Some graph representations may consume significant memory, especially for large graphs.
        </li>
        <li>
          <strong>Traversal Overhead:</strong> Traversing graphs may involve visiting multiple nodes, leading to potential performance overhead.
        </li>
        <li>
          <strong>Algorithmic Challenges:</strong> Certain graph algorithms can be computationally expensive, especially for large graphs with complex structures.
        </li>
      </ul>
      <h2>When to Use Graphs</h2>
      <ul>
        <li>
          <strong>Network Modeling:</strong> Representing relationships in social networks, computer networks, or biological networks.
        </li>
        <li>
          <strong>Routing and Optimization:</strong> Finding shortest paths, optimizing routes, or scheduling tasks.
        </li>
        <li>
          <strong>Recommendation Systems:</strong> Analyzing user preferences and relationships to make recommendations.
        </li>
        <li>
          <strong>Data Representation:</strong> Representing dependencies in tasks or processes.
        </li>
        <li>
          <strong>Constraint Satisfaction:</strong> Modeling constraints and dependencies in various problem-solving tasks.
        </li>
      </ul>
      <h2>Time and Space Complexity of Graph Operations (in tabular form)</h2>
      <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Operation</th>
            <th>Time Complexity (Average)</th>
            <th>Time Complexity (Worst-case)</th>
            <th>Space Complexity</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Add Vertex</td>
            <td>O(1)</td>
            <td>O(1)</td>
            <td>O(1)</td>
          </tr>
          <tr>
            <td>Add Edge</td>
            <td>O(1)</td>
            <td>O(1)</td>
            <td>O(1)</td>
          </tr>
          <tr>
            <td>Remove Vertex</td>
            <td>O(V + E)</td>
            <td>O(V + E)</td>
            <td>O(1)</td>
          </tr>
          <tr>
            <td>Remove Edge</td>
            <td>O(V)</td>
            <td>O(V)</td>
            <td>O(1)</td>
          </tr>
          <tr>
            <td>Search Vertex</td>
            <td>O(1)</td>
            <td>O(1)</td>
            <td>O(1)</td>
          </tr>
          <tr>
            <td>Search Edge</td>
            <td>O(V)</td>
            <td>O(V)</td>
            <td>O(1)</td>
          </tr>
          <tr>
            <td>Traversal</td>
            <td>O(V + E)</td>
            <td>O(V + E)</td>
            <td>O(V)</td>
          </tr>
        </tbody>
      </table>
      </div>
      <h2>Code Example of Graphs in Python</h2>
      <pre>
        <code>
{`class Graph:
    def __init__(self):
        self.graph = {}

    def add_vertex(self, vertex):
        if vertex not in self.graph:
            self.graph[vertex] = []

    def add_edge(self, start, end):
        self.graph[start].append(end)

    def display(self):
        for vertex in self.graph:
            print(vertex, ":", "->".join(self.graph[vertex]))

# Example usage
g = Graph()
g.add_vertex('A')
g.add_vertex('B')
g.add_vertex('C')
g.add_edge('A', 'B')
g.add_edge('B', 'C')
g.add_edge('C', 'A')
g.display()`}
        </code>
      </pre>
      <h2>Graphs Differences in Different Programming Languages (in tabular format)</h2>
      <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Language</th>
            <th>Graph Implementation Libraries/Modules</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Python</td>
            <td>NetworkX, igraph, Graph-tool</td>
          </tr>
          <tr>
            <td>Java</td>
            <td>JGraphT, Apache Commons Graph</td>
          </tr>
          <tr>
            <td>C++</td>
            <td>Boost Graph Library (BGL), Lemon Graph Library</td>
          </tr>
          <tr>
            <td>JavaScript</td>
            <td>Graphlib, Node.js graphlib</td>
          </tr>
          <tr>
            <td>C#</td>
            <td>QuickGraph, GraphX</td>
          </tr>
          <tr>
            <td>Ruby</td>
            <td>Rubygraph</td>
          </tr>
        </tbody>
       
      </table>
      </div>
      </div>
  );
};

export default GraphsExplanation;
