import React from 'react';
import './../contents.css';

const LinkedListsExplanation: React.FC = () => {
    return (
        <div className="container">
          <h2>Linked Lists in Programming</h2>
          <p>Linked lists are a fundamental data structure in programming, used to store a collection of elements. Unlike arrays, which store elements in contiguous memory locations, linked lists store elements as individual nodes, each containing a value and a reference (or pointer) to the next node in the sequence. This structure allows for dynamic memory allocation and efficient insertion and deletion operations.</p>
    
          <h2>Characteristics of Linked Lists</h2>
          <ul>
            <li><strong>Dynamic Size:</strong> Linked lists can dynamically grow and shrink in size, as memory allocation is done dynamically for each node.</li>
            <li><strong>Non-contiguous Memory:</strong> Elements in a linked list are not stored in contiguous memory locations, allowing for efficient insertion and deletion at any position.</li>
            <li><strong>Traversal:</strong> Traversing a linked list requires iterating through each node sequentially, starting from the head (or beginning) of the list.</li>
            <li><strong>No Random Access</strong> Unlike arrays, linked lists do not support random access to elements. Accessing an element at a specific index requires traversing the list from the beginning.</li>
          </ul>
    
          <h2>Advantages of Linked Lists</h2>
          <ul>
            <li><strong>Dynamic Size</strong> Linked lists can easily grow or shrink in size without the need for resizing operations.</li>
            <li><strong>Efficient Insertion and Deletion:</strong> Insertion and deletion operations can be performed efficiently, especially for large lists, as they only involve updating pointers.</li>
            <li><strong>No Wasted Memory:</strong> Linked lists use memory efficiently, allocating memory only as needed for each node.</li>
            <li><strong>Versatility:</strong> Linked lists can be implemented in various forms, such as singly linked lists, doubly linked lists, or circular linked lists, to suit different requirements.</li>
          </ul>
    
          <h2>Disadvantages of Linked Lists</h2>
          <ul>
            <li><strong>No Random Access:</strong> Accessing elements by index is inefficient, as it requires traversing the list from the beginning.</li>
            <li><strong>Extra Memory Overhead:</strong> Each node in a linked list requires additional memory for storing pointers, increasing memory overhead compared to arrays.</li>
            <li><strong>Traversal Overhead:</strong> Traversing a linked list sequentially can be slower compared to arrays, especially for large lists.</li>
            <li><strong>Not Cache-friendly:</strong> Linked lists may not utilize hardware caching efficiently due to their non-contiguous memory layout.</li>
          </ul>
    
          <h2>When to Use Linked Lists</h2>
          <ul>
            <li><strong>Frequent Insertions and Deletions:</strong> Linked lists are suitable when frequent insertions or deletions are required, as they offer efficient operations for these tasks.</li>
            <li><strong>Dynamic Size Requirements:</strong> When the size of the data structure needs to change frequently, linked lists provide a flexible solution without the need for resizing operations.</li>
            <li><strong>Sequential Access:</strong> If the primary operation is sequential traversal without the need for random access, linked lists can be a good choice.</li>
          </ul>

          <h2 className="subheading"> Linked Lists Code Example in Python</h2>
            <pre>
                <code className="language-python">
                    class Node:<br/>
                        def __init__(self, data):<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp; self.data = data<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp; self.next = None<br/>
                            <br/>
                    class LinkedList:<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp; def __init__(self):<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  self.head = None<br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp; def append(self, data):<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  new_node = Node(data)<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  if not self.head:<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;       self.head = new_node<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;       return<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  last_node = self.head<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  while last_node.next:<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;      last_node = last_node.next<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; last_node.next = new_node<br/>
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp; def display(self):<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; current = self.head<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; while current:<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; print(current.data, end=" -{`>`} ")<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; current = current.next<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; print("None")<br/>
                            <br/>
                    # Example usage<br/>
                    if __name__ == "__main__":<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp; linked_list = LinkedList()<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp; linked_list.append(1)<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp; linked_list.append(2)<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp; linked_list.append(3)<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp; linked_list.display()<br/>

                </code>
            </pre>
    
          <h2>Linked Lists Differences in Different Programming Languages</h2>
          <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Language</th>
                <th>Implementation</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>C</td>
                <td>Pointers and structs</td>
                <td>Manual memory management required</td>
              </tr>
              <tr>
                <td>C++</td>
                <td>Pointers and classes</td>
                <td>Can use smart pointers for memory management</td>
              </tr>
              <tr>
                <td>Java</td>
                <td>LinkedList class (Java Collections Framework)</td>
                <td>Provides built-in implementation</td>
              </tr>
              <tr>
                <td>Python</td>
                <td>Lists (using references)</td>
                <td>Dynamic arrays, memory management handled by interpreter</td>
              </tr>
              <tr>
                <td>JavaScript</td>
                <td>Object-based representation</td>
                <td>Emulates linked lists using objects</td>
              </tr>
              <tr>
                <td>C#</td>
                <td>LinkedList{`<T>`} class (System.Collections.Generic)</td>
                <td>Provides built-in implementation</td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
      );
}

export default LinkedListsExplanation;
