import React from 'react';
import './../contents.css';

const BacktrackingExplanation : React.FC = () => {
    return (
        <div className="container">
            <h2>Backtracking Explanation</h2>
            <p>Backtracking is a powerful algorithmic technique used to systematically search for solutions to computational problems, especially in situations where a brute-force approach would be impractical due to the problem's large search space. It is particularly useful for solving problems involving choices or decisions that can be represented as a sequence of steps.</p>
            
            <h2>Steps in using backtracking</h2>
            <ol>
                <li>Choose: Make a choice for the next step towards a solution.</li>
                <li>Explore: Recursively explore all possible options from the current choice.</li>
                <li>Backtrack: If a choice leads to a dead end (i.e., it doesn't contribute to a solution), undo the choice and try another option.</li>
                <li>Base case: Terminate the recursion when a solution is found or when all possibilities have been explored.</li>
            </ol>
            
            <h2>When to use backtracking</h2>
            <p>Backtracking is suitable for problems that exhibit the following characteristics:</p>
            <ul>
                <li>Optimization or Decision problems</li>
                <li>Exhaustive search</li>
                <li>Constraint satisfaction</li>
            </ul>
            
            <h2>Data structure for backtracking</h2>
            <p>The choice of data structure depends on the specific problem being solved. Commonly used data structures include arrays, lists, sets, and matrices to represent the problem space and track the state of the search.</p>
            
            <h2>Common problems solved using backtracking technique</h2>
            <ul>
                <li>N-Queens problem</li>
                <li>Sudoku</li>
                <li>Subset sum problem</li>
                <li>Knight's tour problem</li>
                <li>Graph coloring problem</li>
                <li>Generate all permutations/combinations</li>
            </ul>
            
            <h2>Code example in Python (N-Queens problem)</h2>
            <pre>
            <code>
{`def is_safe(board, row, col):
    for i in range(row):
        if board[i] == col or \\
            board[i] - i == col - row or \\
            board[i] + i == col + row:
            return False
    return True

def solve_n_queens(n, board, row, solutions):
    if row == n:
        solutions.append(list(board))
        return
    for col in range(n):
        if is_safe(board, row, col):
            board[row] = col
            solve_n_queens(n, board, row + 1, solutions)
            board[row] = -1

def n_queens(n):
    board = [-1] * n
    solutions = []
    solve_n_queens(n, board, 0, solutions)
    return solutions

n = 4
print("Solutions for {}-Queens problem:".format(n))
for solution in n_queens(n):
    print(solution)`}
                </code>
            </pre>

            <p>Explanation of the code:
                is_safe function checks whether placing a queen at a given position (row, col) is safe or not.
                solve_n_queens recursively explores all possible solutions by placing queens row by row.
                n_queens function initializes the board and collects all solutions.
                The code prints all solutions for the 4-Queens problem.
            </p>
            
            <p>
                <strong>Time complexity:</strong> The time complexity of backtracking algorithms varies depending on the problem, but it typically ranges from exponential to polynomial time.
                <br />
                <strong>Space complexity:</strong> Backtracking algorithms usually have a space complexity of O(N) where N is the size of the problem space.
            </p>
        </div>
    );
}

export default BacktrackingExplanation;
