import React from 'react';
import './../contents.css';

const TopologicalSortingExplanation : React.FC = () => {
    return (
        <div className="container">
                <h2>Topological Sorting Algorithm Explanation</h2>
                <p>Topological Sorting is a fundamental algorithm used to order the nodes in a directed acyclic graph (DAG) such that for every directed edge from node u to node v, u comes before v in the ordering. This ordering is not unique, as multiple valid topological orderings may exist for a given DAG.</p>

                <h2>When to Use Topological Sorting Algorithm</h2>
                <ul>
                    <li>Dependency Resolution: When tasks or activities have dependencies, and you need to determine the order in which they should be executed.</li>
                    <li>Course Prerequisites: In academic scenarios, when scheduling courses that have prerequisites.</li>
                    <li>Build Systems: In build systems like Makefiles, where certain targets depend on others.</li>
                    <li>Job Scheduling: When scheduling jobs that have dependencies or requirements.</li>
                    <li>Symbol Resolution: In compilers, where resolving symbols or dependencies is necessary.</li>
                    <li>Workflow Management: In workflow management systems to order the execution of tasks.</li>
                </ul>

                <h2>Data Structure Used in Topological Sorting</h2>
                <p>The algorithm typically uses a graph representation, often an adjacency list or adjacency matrix to represent the directed graph.</p>

                <h2>Steps for Topological Sorting Algorithm</h2>
                <ol>
                    <li><strong>Initialize:</strong> Initialize an empty stack and a boolean array to mark visited nodes.</li>
                    <li><strong>DFS Traversal:</strong> Perform a Depth-First Search (DFS) traversal of the graph, starting from any unvisited node.</li>
                    <li><strong>Visit Nodes:</strong> For each visited node, recursively visit its adjacent nodes that have not yet been visited.</li>
                    <li><strong>Push to Stack:</strong> After visiting all adjacent nodes, push the current node onto the stack.</li>
                    <li><strong>Reverse Stack:</strong> Once all nodes are visited, reverse the stack to get the topological ordering.</li>
                </ol>

                <h2>Common Problems Solved Using Topological Sorting Algorithm</h2>
                <ul>
                    <li>Course Scheduling: Determining the order in which to take courses based on prerequisites.</li>
                    <li>Task Scheduling: Scheduling tasks with dependencies in a project management scenario.</li>
                    <li>Symbol Resolution: Resolving symbols or dependencies in compilers.</li>
                    <li>Build Order Resolution: Determining the order of compilation or building in a build system.</li>
                </ul>

                <h2>Code Example in Python</h2>
                <pre><code>
                    {`
from collections import defaultdict

class Graph:
    def __init__(self):
        self.graph = defaultdict(list)

    def add_edge(self, u, v):
        self.graph[u].append(v)

    def topological_sort_util(self, v, visited, stack):
        visited[v] = True
        for i in self.graph[v]:
            if not visited[i]:
                self.topological_sort_util(i, visited, stack)
        stack.append(v)

    def topological_sort(self):
        visited = [False] * len(self.graph)
        stack = []
        for i in range(len(self.graph)):
            if not visited[i]:
                self.topological_sort_util(i, visited, stack)
        return stack[::-1]

# Example usage:
g = Graph()
g.add_edge(5, 2)
g.add_edge(5, 0)
g.add_edge(4, 0)
g.add_edge(4, 1)
g.add_edge(2, 3)
g.add_edge(3, 1)

print("Topological Sorting of the given graph is:")
print(g.topological_sort())

                    `}
                </code></pre>
                <p>Explanation: Explanation:
We create a graph using defaultdict, where each key represents a node, and the corresponding value is a list of its adjacent nodes.
We define a method topological_sort_util that performs DFS traversal and recursively calls itself for each adjacent node.
The topological_sort method iterates through all nodes, calling topological_sort_util for each unvisited node.
The result is a reversed stack, which represents the topological ordering of the graph.</p>
                <p>Time and Space Complexity:</p>
                <ul>
                    <li><strong>Time Complexity:</strong> O(V + E), where V is the number of vertices and E is the number of edges in the graph.</li>
                    <li><strong>Space Complexity:</strong> O(V), where V is the number of vertices, for the stack and visited array.</li>
                </ul>
            </div>
        );
    }

export default TopologicalSortingExplanation;
